import { Form } from "react-bootstrap";

const CustomCheckbox = (props) => {
  const { checkboxList, isCheckboxChecked, handleOnChangeCheckbox } = props;
  return (
    <div
      style={{
        marginTop: "3%",
      }}
    >
      {Object.keys(checkboxList).map((label, index) => (
        <div key={index} style={{ display: "flex", flexDirection: "column" }}>
          <Form.Check
            inline
            label={label}
            name="group1"
            type={"checkbox"}
            id={index}
            value={checkboxList[label]}
            checked={isCheckboxChecked[checkboxList[label]] === true}
            onChange={() => handleOnChangeCheckbox(checkboxList[label])}
            style={{ marginBottom: "3%" }}
          />
        </div>
      ))}
    </div>
  );
};

export default CustomCheckbox;
