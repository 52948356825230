import { Modal } from "react-bootstrap";
import "./filter-sort-mobile-modal.style.css";
import { neturalBlack, primaryOrange } from "../../utils/colors";
import { useEffect, useState } from "react";
import FilterMobile from "../filter-section/filter-mobile-section.component";
import { IoClose } from "react-icons/io5";
import CustomLabel from "../shared/label/custom-label.component";
import CustomButton from "../button/button.component";
import SortMobile from "../sort-mobile-section/sort-mobile-section.component";
import { useNavigate } from "react-router-dom";

const FilterSortMobileModal = (props) => {
  const {
    setSelectedCategory,
    selectedCategory,
    setSelectedSubcategories,
    selectedSubcategories,
    isMobileFliterPrice,
    isMobileFliterDeals,
    type,
    mobileFilterServiceType,
    handleLocationChange,
    isSortValue,
  } = props;
  const navigation = useNavigate();
  const [isFliterDeals, setIsFilterDeals] = useState(
    isMobileFliterDeals ? isMobileFliterDeals : {}
  );
  const [isSortedValue, setIsSortedValue] = useState(
    isSortValue ? isSortValue : "Featured"
  );
  //   const [searchedText, setSearchedText] = useState("");
  const [isFliterPrice, setIsFilterPrice] = useState(
    isMobileFliterPrice ? isMobileFliterPrice : {}
  );
  const [filteredData, setFilteredData] = useState({
    minPrice: "",
    maxPrice: "",
  });
  const [filterServiceType, setFilterServiceType] = useState(
    mobileFilterServiceType ? mobileFilterServiceType : {}
  );
  const [totalSelected, setTotalSelected] = useState(0);
  // const [isSortValue, setIsSortValue] = useState("Featured");
  const [currentSelectedSubcategories, setCurrentSeletcedSubcategories] =
    useState(selectedSubcategories);

  useEffect(() => {
    if (isSortValue && isSortValue === "price") {
      setIsSortedValue("Price (low-high)");
    } else if (isSortValue && isSortValue === "-price") {
      setIsSortedValue("Price (high-low)");
    } else if (isSortValue === "Featured" || isSortValue === "") {
      setIsSortedValue("Featured");
    } else {
      setIsSortedValue(isSortValue);
    }
  }, [isSortValue]);

  useEffect(() => {
    if (
      Object.keys(isFliterPrice).length > 0 ||
      Object.keys(isFliterDeals).length > 0 ||
      currentSelectedSubcategories.length > 0 ||
      (type === "Services" &&
        (filterServiceType?.online || filterServiceType?.inPerson))
    ) {
      setTotalSelected(
        parseInt(
          Object.keys(isFliterPrice).length +
            Object.keys(isFliterDeals).length +
            currentSelectedSubcategories.length +
            Object.keys(filterServiceType).length
        )
      );
    } else {
      setTotalSelected(0);
    }
  }, [
    isFliterPrice,
    isFliterDeals,
    currentSelectedSubcategories,
    filterServiceType,
  ]);

  const calculateMinMax = (selectedValue) => {
    const numericalRanges = Object.keys(selectedValue).map((key) => {
      const [min, max] = key.split("-").map(Number);
      return { min, max };
    });

    // Find the minimum and maximum values from the numerical ranges
    let minValue = numericalRanges[0].min || 0;
    let maxValue = numericalRanges[0].max;
    numericalRanges.forEach((range) => {
      if (range.min < minValue) {
        minValue = range.min;
      }
      if (range.max > maxValue || isNaN(range.max)) {
        maxValue = range.max;
      }
    });
    // let minValue = 0;
    // let maxValue = 0;
    return { minValue, maxValue };
  };

  const handleOnChangeFilterPrice = (id) => {
    const newCheckedItems = { ...isFliterPrice };
    if (newCheckedItems[id]) {
      delete newCheckedItems[id];
    } else {
      newCheckedItems[id] = true;
    }

    setIsFilterPrice(newCheckedItems);
    if (Object.keys(newCheckedItems).length !== 0) {
      const { minValue, maxValue } = calculateMinMax(newCheckedItems);

      setFilteredData((previousData) => ({
        ...previousData,
        minPrice: minValue.toString(),
        maxPrice: maxValue.toString(),
      }));
    } else {
      setFilteredData({});
      setIsFilterPrice({});
    }
  };

  const handleOnChangeFilterDeals = (id) => {
    const newCheckedItems = { ...isFliterDeals };
    if (newCheckedItems[id]) {
      delete newCheckedItems[id];
    } else {
      newCheckedItems[id] = true;
    }
    setIsFilterDeals(newCheckedItems);
    setFilteredData((previousData) => ({
      ...previousData,
      discount: "discount",
    }));
  };

  const handleOnChnageFilterServiceType = (label, checked) => {
    setFilteredData((prevState) => {
      const key = label === "Online" ? "serviceOnline" : "serviceInPerson";
      if (checked) {
        const updatedState = {
          ...prevState,
          [key]: checked,
        };
        return updatedState;
      } else {
        const updatedState = { ...prevState };
        delete updatedState[key];
        return updatedState;
      }
    });
    setFilterServiceType((prevState) => {
      const key = label === "Online" ? "online" : "inPerson";
      const updatedState = { ...prevState };

      if (checked) {
        updatedState[key] = true;
      } else {
        delete updatedState[key];
      }

      return updatedState;
    });
  };

  const handleOnChangeCategory = (label) => {
    setSelectedCategory(label.name);
    if (label.subCategoryCount > 0) {
      // props.onHide();
      props.resetFilterSort();
      navigation(`/products/category/${label.name}`);
    } else {
      props.onHide();
    }
  };

  const handleCurrentCheckChange = (e) => {
    const checkedValue = { target: { value: e } };
    props.handleCheckChange(checkedValue);
    // console.log("acnbascjhacjhaca",e)
    setIsSortedValue(e);
  };

  return (
    <Modal {...props} fullscreen className="filter-mobile-modal">
      <div
        style={{
          padding: "3%",
          borderBottom: "1px solid #dadada",
        }}
      >
        <Modal.Header
          closeButton={props.title === "Sort" ? true : false}
          style={{
            borderBottom: "0px solid rgb(0, 0, 0, 0.1)",
            paddingLeft: "3%",
            paddingRight: "3%",
            backgroundColor: "white",
            borderRadius: "12px",
            paddingBottom: "0px",
            width: "100%",
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            <CustomLabel
              style={{
                color: neturalBlack,
              }}
              className="filter-mobile-modal-header-text"
            >
              {props.title}
            </CustomLabel>
          </Modal.Title>
          {props.title === "Filters" && (
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {Object.keys(isFliterPrice).length > 0 ||
              Object.keys(isFliterDeals).length > 0 ||
              currentSelectedSubcategories.length > 0 ||
              filterServiceType?.online ||
              filterServiceType?.inPerson ? (
                <CustomLabel
                  style={{
                    color: primaryOrange,
                    marginRight: "3%",
                  }}
                  className="filter-mobile-modal-header-text"
                  onClick={() => {
                    props.resetFilterSort();
                    props.onHide();
                  }}
                >
                  Clear all{" "}
                  {"[" +
                    parseInt(
                      Object.keys(isFliterPrice).length +
                        Object.keys(isFliterDeals).length +
                        currentSelectedSubcategories.length +
                        (filterServiceType.online ? 1 : 0) +
                        (filterServiceType.inPerson ? 1 : 0)
                    ) +
                    "]"}
                </CustomLabel>
              ) : null}
              <IoClose
                onClick={() => {
                  setCurrentSeletcedSubcategories(selectedSubcategories);
                  setTotalSelected(
                    parseInt(
                      totalSelected - currentSelectedSubcategories.length
                    )
                  );
                  props.onHide();
                }}
              />
            </Modal.Title>
          )}
        </Modal.Header>
      </div>
      <div style={{ padding: "3%", height: "88vh", overflowX: "auto" }}>
        <Modal.Body
          style={{
            marginBottom: "2%",
            paddingLeft: "3%",
            paddingRight: "3%",
            paddingTop: "1%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {props.title === "Filters" ? (
            <FilterMobile
              isFliterPrice={isFliterPrice}
              isFliterDeals={isFliterDeals}
              setIsFilterDeals={setIsFilterDeals}
              setIsFilterPrice={setIsFilterPrice}
              setFilteredData={setFilteredData}
              handleOnChangeFilterPrice={handleOnChangeFilterPrice}
              handleOnChangeFilterDeals={handleOnChangeFilterDeals}
              handleOnChangeCategory={handleOnChangeCategory}
              categoryName={props.categoryName}
              setSelectedCategory={setSelectedCategory}
              setSelectedSubcategories={setCurrentSeletcedSubcategories}
              selectedCategory={selectedCategory}
              selectedSubcategories={currentSelectedSubcategories}
              type={type}
              filterServiceType={filterServiceType}
              setFilterServiceType={setFilterServiceType}
              handleOnChnageFilterServiceType={handleOnChnageFilterServiceType}
              handleLocationChange={handleLocationChange}
            />
          ) : props.title === "Sort" ? (
            <SortMobile
              isSortValue={isSortedValue}
              handleCheckChange={handleCurrentCheckChange}
            />
          ) : null}
          <div style={{ display: "flex", alignItems: "end" }}>
            <CustomButton
              title={`${"Apply "}${
                totalSelected !== 0 && props.title === "Filters"
                  ? "[" + totalSelected + "]"
                  : ""
              }`}
              buttonType="validation"
              isValid={
                (props.title === "Filters" && totalSelected === 0) ||
                (props.title === "Sort" && isSortedValue === "")
                  ? false
                  : true
              }
              handleButtonClick={() => {
                props.onHide();
                props.handleButtonClick(
                  filteredData,
                  isSortedValue,
                  currentSelectedSubcategories,
                  filterServiceType,
                  isFliterPrice,
                  isFliterDeals
                );
              }}
            />
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default FilterSortMobileModal;
