import { Fragment, useEffect, useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import CustomButton from "../button/button.component";

const EditAddressForm = ({
  handleEditAddress,
  section2Enabled,
  buttonTitle,
  selectedAddress,
}) => {
  const [editAddressFormValid, setEditAddressFormValid] = useState(false);
  const [editedAddress, setEditedAddress] = useState({
    address: "",
    shippingCityName: "",
    shippingProvince: "",
    shippingCountry: "",
    shippingPostalCode: "",
    unitNumber: "",
  });

  const convertAddress = (editAddress) => {
    let shippingAddress = editAddress.address.split(", ");
    let provinceInfo = shippingAddress[2].split(" ");
    let shippingCityName;
    let shippingProvince;
    let shippingPostalCode = "";
    let shippingCountry;
    let address;
    if (shippingAddress.length <= 4) {
      address = shippingAddress[0];
      shippingCityName = shippingAddress[1];
      if (provinceInfo.length > 1) {
        shippingProvince = shippingAddress[2].substring(
          0,
          shippingAddress[2].indexOf(" ")
        );
        shippingPostalCode = shippingAddress[2].substring(
          shippingAddress[2].indexOf(" ") + 1
        );
      } else {
        shippingPostalCode = provinceInfo[0];
      }
      shippingCountry = shippingAddress[3];
    } else {
      address = shippingAddress[0];
      shippingCityName = shippingAddress[1];
      shippingProvince = shippingAddress[2];
      shippingCountry = shippingAddress[3];
      shippingPostalCode = shippingAddress[4];
    }
    let finalAddress = {
      address,
      shippingCityName,
      shippingProvince,
      shippingCountry,
      shippingPostalCode,
      unitNumber: editAddress.unitNumber,
    };
    return finalAddress;
  };

  useEffect(() => {
    if (Object.keys(selectedAddress).length !== 0) {
      let finalAddress = convertAddress(selectedAddress);
      setEditedAddress(finalAddress);
    }
  }, [selectedAddress]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const validateEditedAddress = () => {
    const {
      address,
      shippingCityName,
      shippingProvince,
      shippingCountry,
      shippingPostalCode,
    } = editedAddress;
    if(address && shippingCityName && shippingProvince && shippingCountry && shippingPostalCode){
    // Perform validation checks here
    const isValid =
      address.trim() !== "" &&
      shippingCityName.trim() !== "" &&
      shippingProvince.trim() !== "" &&
      shippingCountry.trim() !== "" &&
      shippingPostalCode.trim() !== "";
    setEditAddressFormValid(isValid);
    }else{
      setEditAddressFormValid(false);
    }
  };

  useEffect(() => {
    validateEditedAddress();
  }, [editedAddress]);

  return (
    <Fragment>
      <div style={{ marginTop: "5%" }}>
        <FloatingLabel controlId="floatingInput" label="Country">
          <Form.Control
            type="name"
            className="pt-4 mt-4"
            style={{ borderRadius: "12px", paddingTop: "4%" }}
            placeholder="Country"
            name="shippingCountry"
            value={editedAddress.shippingCountry}
            onChange={handleChange}
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="Address">
          <Form.Control
            type="name"
            className="pt-4 mt-4"
            style={{ borderRadius: "12px", paddingTop: "4%" }}
            placeholder="Address"
            name="address"
            value={editedAddress.address}
            onChange={handleChange}
          />
          <FloatingLabel
            controlId="floatingInput"
            label="Unit, apartment, suite, etc (optional)"
          >
            <Form.Control
              type="name"
              className="pt-4 mt-4"
              style={{ borderRadius: "12px", paddingTop: "4%" }}
              placeholder="Unit, apartment, suite, etc (optional)"
              name="unitNumber"
              value={editedAddress.unitNumber}
              onChange={handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Town/Montreal">
            <Form.Control
              type="name"
              className="pt-4 mt-4"
              style={{ borderRadius: "12px", paddingTop: "4%" }}
              placeholder="Town/Montreal"
              name="shippingCityName"
              value={editedAddress.shippingCityName}
              onChange={handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Province">
            <Form.Control
              type="name"
              className="pt-4 mt-4"
              style={{ borderRadius: "12px", paddingTop: "4%" }}
              placeholder="Province"
              name="shippingProvince"
              value={editedAddress.shippingProvince}
              onChange={handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Postal Code">
            <Form.Control
              type="name"
              className="pt-4 mt-4"
              style={{ borderRadius: "12px", paddingTop: "4%" }}
              placeholder="Postal Code"
              name="shippingPostalCode"
              value={editedAddress.shippingPostalCode}
              onChange={handleChange}
            />
          </FloatingLabel>
        </FloatingLabel>
      </div>
      {!section2Enabled && (
        <div style={{ marginTop: "5%" }}>
          <CustomButton
            title={buttonTitle}
            // processing={isLoading}
            buttonType={"validation"}
            isValid={editAddressFormValid}
            handleButtonClick={() => {
              handleEditAddress(editAddressFormValid, editedAddress);
            }}
          />
        </div>
      )}
    </Fragment>
  );
};

export default EditAddressForm;
