import { gql } from "@apollo/client";

export const FETCH_LANDING_PAGE_INFO = gql`
  query fetchingLandingPageInfo {
    fetchingLandingPageInfo {
      _id
      carouselImages {
        imageUrl
        title
        id
      }
      products {
        _id
        companyName
        discount
        finalTotal
        itemType
        medias {
          height
          key
          mediaType
          uri
        }
        price
        title
      }
      trustedPatners {
        id
        imageUrl
        title
      }
    }
  }
`;
