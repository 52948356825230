import { Fragment } from "react";
import { primaryOrange } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";
import { ITEM_TYPE } from "../../utils/constants";

const ProductDetailPrice = ({
  product,
  selectedVariant,
  priceStyle,
  finalTotalStyle,
}) => {
  return (
    <Fragment>
      {((product.variants?.length === 0 &&
        product.itemType === ITEM_TYPE.PRODUCT) ||
        product.itemType === ITEM_TYPE.SERVICE) &&
      product.discount === "0" ? (
        <CustomLabel
          style={{
            fontWeight: "600",
            color: primaryOrange,
            marginRight: "2%",
            ...finalTotalStyle,
          }}
        >
          ${product.finalTotal}
        </CustomLabel>
      ) : (
        <div>
          <CustomLabel
            style={{
              fontWeight: "600",
              color: primaryOrange,
              marginRight: "2%",
              ...finalTotalStyle,
            }}
          >
            ${selectedVariant?.finalTotal || product.finalTotal}
          </CustomLabel>
          {selectedVariant?.discount !== "0" && (
            <CustomLabel
              style={{ textDecoration: "line-through", ...priceStyle }}
            >
              ${selectedVariant?.price || product.price}
            </CustomLabel>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default ProductDetailPrice;
