import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { rootReducer } from './root-reducer';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import CryptoJS from 'crypto-js';

const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_ENCRYPTION_KEY,
      onError: function (error) {
        // Handle encryption error
      },
      // You can also specify an encryption method, such as AES
       encryptionMethod: CryptoJS.AES,
    }),
  ],
//   whitelist: ['auth'], // Only persist the 'auth' slice
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
