import { Form } from "react-bootstrap";
import Collapsible from "react-collapsible";
import CustomLabel from "../shared/label/custom-label.component";

const Sort = (props) => {
  const { isSortValue, handleCheckChange } = props;
  return (
    <div style={{ paddingLeft: "8%", paddingRight: "5%" }}>
      <div style={{ marginTop: "8%", marginBottom: "8%" }}>
        <CustomLabel style={{ fontSize: "18px", fontWeight: "bold" }}>
          Sort by
        </CustomLabel>
      </div>
      <Collapsible open>
        <div
          style={{
            marginTop: "3%",
          }}
        >
          {["Featured", "Price (low-high)", "Price (high-low)"].map(
            (label, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  htmlFor={`radio-${index}`}
                  style={{
                    marginBottom: "3%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    id={`radio-${index}`}
                    type="radio"
                    name="group1"
                    value={
                      label === "Featured"
                        ? ""
                        : label === "Price (low-high)"
                        ? "price"
                        : label === "Price (high-low)"
                        ? "-price"
                        : ""
                    }
                    checked={
                      isSortValue ===
                      (label === "Featured"
                        ? ""
                        : label === "Price (low-high)"
                        ? "price"
                        : "-price")
                    }
                    onChange={handleCheckChange}
                    className="custom-radio"
                  />
                  {label}
                </label>
              </div>
            )
          )}
        </div>
      </Collapsible>
    </div>
  );
};

export default Sort;
