import { Col, Form } from "react-bootstrap";
import Sort from "../sort-section/sort-section.component";
import Filter from "../filter-section/filter-section.component";
import CustomLabel from "../shared/label/custom-label.component";
import { primaryOrange } from "../../utils/colors";
import { useNavigate } from "react-router-dom";
import Collapsible from "react-collapsible";
import CloseFilterTitle from "../filter-title-section/filter-title-close-section.component";
import OpenFilterTitle from "../filter-title-section/filter-title-open-section.component";
import { Typeahead } from "react-bootstrap-typeahead";
import { useEffect, useState } from "react";
import { City, Country, State } from "country-state-city";

const FilterSortColumn = (props) => {
  const {
    isFliterPrice,
    isFliterDeals,
    setIsFilterDeals,
    setIsFilterPrice,
    setFilteredData,
    handleOnChangeFilterPrice,
    handleOnChangeFilterDeals,
    isSortValue,
    handleCheckChange,
    setSelectedCategory,
    selectedCategory,
    setSelectedSubcategories,
    selectedSubcategories,
    setPageNumber,
    searchedText,
    type,
    resetFilterSort,
    filterServiceType,
    setFilterServiceType,
    handleOnChnageFilterServiceType,
    serviceLocationArray,
  } = props;
  const navigation = useNavigate();
  const [allCountry, setAllCountry] = useState([]);
  const [state, setState] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [city, setCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  let changeCountry = false;

  useEffect(() => {
    if (searchedText) {
      setAllCountry([]);
      setSelectedState([]);
      setSelectedCity([]);
    }
  }, [searchedText]);

  const getState = () => {
    if (allCountry.length !== 0) {
      setState(State.getStatesOfCountry(allCountry[0].isoCode));
    }
  };
  const getCity = () => {
    if (!changeCountry) {
      if (selectedState.length !== 0) {
        setCity(
          City.getCitiesOfState(allCountry[0].isoCode, selectedState[0].isoCode)
        );
      }
    }
  };

  useEffect(() => {
    if (serviceLocationArray.country?.length > 0) {
      setAllCountry(serviceLocationArray.country);
      setSelectedState(serviceLocationArray.state);
    }
  }, [serviceLocationArray]);

  useEffect(() => {
    if (selectedState.length !== 0) {
      getCity();
    } else {
      setSelectedCity([]);
    }
  }, [selectedState]);

  useEffect(() => {
    if (allCountry.length !== 0 && serviceLocationArray.country.length === 0) {
      getState();
    } else if (serviceLocationArray?.country?.length === 0) {
      setSelectedState([]);
      setSelectedCity([]);
      changeCountry = true;
    } else {
      getState();
    }
  }, [allCountry, changeCountry]);

  return (
    <Col
      xl={2}
      lg={2}
      md={2}
      sm={12}
      xs={12}
      className="scrollable-filter-column"
      style={{ maxHeight: "85vh" }}
    >
      {selectedCategory !== "Pets and Animals" && type === "Products" ? (
        <div
          style={{ display: "flex", justifyContent: "center" }}
          onClick={() => {
            // navigation(`/products/category/Pets and Animals`)
            resetFilterSort();
            setSelectedCategory("Pets and Animals");
          }}
        >
          <CustomLabel
            style={{ color: primaryOrange, textDecoration: "underline" }}
          >
            Pets and Animals
          </CustomLabel>
        </div>
      ) : null}
      <Sort isSortValue={isSortValue} handleCheckChange={handleCheckChange} />
      <div
        style={{
          marginTop: "15%",
          marginBottom: "15%",
          borderTop: "solid 1px #e0e0e0",
        }}
      ></div>
      {type === "Services" && (
        <>
          <div style={{ paddingLeft: "8%", paddingRight: "5%" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <label style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Location
                </label>
                <div style={{ marginTop: "5%" }}>
                  <Form.Group controlId="validationCustom01">
                    <Form.Label>Country / Region</Form.Label>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      onChange={(country) => {
                        setAllCountry(country);
                        props.handleLocationChange(country, [], []);
                      }}
                      options={Country.getAllCountries()}
                      placeholder="Country / Region"
                      selected={allCountry}
                      inputProps={{ required: true }}
                    />
                    <Form.Control.Feedback>
                      Please select a valid Country / Region.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div style={{ marginTop: "8%" }}>
                  <Form.Group controlId="validationCustom01">
                    <Form.Label>State / Province</Form.Label>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      onChange={(state) => {
                        setSelectedState(state);
                        props.handleLocationChange(allCountry, state, []);
                      }}
                      options={state}
                      placeholder="State / Province"
                      selected={selectedState}
                      disabled={allCountry.length === 0 ? true : false}
                      inputProps={{ required: true }}
                    />
                    <Form.Control.Feedback>
                      Please select a valid State / Province.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div style={{ marginTop: "8%" }}>
                  <Form.Group controlId="validationCustom01">
                    <Form.Label>City</Form.Label>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      onChange={(city) => {
                        setSelectedCity(city);
                        props.handleLocationChange(
                          allCountry,
                          selectedState,
                          city
                        );
                      }}
                      options={city}
                      placeholder="City"
                      selected={selectedCity}
                      disabled={selectedState.length === 0 ? true : false}
                      inputProps={{ required: true }}
                    />
                    <Form.Control.Feedback>
                      Please select a valid City.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "15%",
              marginBottom: "15%",
              borderTop: "solid 1px #e0e0e0",
            }}
          ></div>
        </>
      )}

      <Filter
        isFliterPrice={isFliterPrice}
        isFliterDeals={isFliterDeals}
        setIsFilterDeals={setIsFilterDeals}
        setIsFilterPrice={setIsFilterPrice}
        setFilteredData={setFilteredData}
        handleOnChangeFilterPrice={handleOnChangeFilterPrice}
        handleOnChangeFilterDeals={handleOnChangeFilterDeals}
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
        setSelectedSubcategories={setSelectedSubcategories}
        selectedSubcategories={selectedSubcategories}
        setPageNumber={setPageNumber}
        searchedText={searchedText}
        type={type}
        filterServiceType={filterServiceType}
        setFilterServiceType={setFilterServiceType}
        handleOnChnageFilterServiceType={handleOnChnageFilterServiceType}
      />
    </Col>
  );
};

export default FilterSortColumn;
