import { USER_ACTION_TYPES } from "./user.types";

const initialState = {
  isUserLoggedIn: false,
  currentUser: null,
  isLoading: false,
  error: null,
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_ACTION_TYPES.SIGN_IN_SUCCESS:
      return {
        currentUser: payload.currentUser,
        isUserLoggedIn: true,
        isLoading: false,
        error: null,
      };
    case USER_ACTION_TYPES.SIGN_IN_FAILED:
    case USER_ACTION_TYPES.SIGN_OUT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case USER_ACTION_TYPES.SIGN_OUT_SUCCESS:
      return {
        ...initialState,
      };
    case USER_ACTION_TYPES.UPDATE_CURRENT_USER:
      return {
        currentUser: { ...state.currentUser, ...payload.currentUser },
        isUserLoggedIn: true,
        isLoading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default userReducer;
