import React from "react";
import PropTypes from "prop-types";
import "./loading-spinner.styles.css";

const LoadingSpinner = ({ overlay, color, text }) => {
  const spinnerStyle = {
    borderTopColor: color,
  };

  return (
    <>
      {overlay && (
        <div className="loading-spinner-overlay">
          <div className="loading-spinner-container">
            <div className={"loading-spinner"} style={spinnerStyle}></div>
            {text && <span className="loading-spinner-text">{text}</span>}
          </div>
        </div>
      )}
      {!overlay && (
        <div className="loading-spinner-container">
          <div className={"loading-spinner"} style={spinnerStyle}></div>
          {text && <span className="loading-spinner-text">{text}</span>}
        </div>
      )}
    </>
  );
};

LoadingSpinner.propTypes = {
  overlay: PropTypes.bool,
  color: PropTypes.string,
  text: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  overlay: false,
  color: "#FF8D24", // Default color
};

export default LoadingSpinner;
