import { Fragment } from "react";
import { primaryOrange } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";

const ProductPrice = ({price, finalTotal, priceStyle, finalTotalStyle}) =>{

    return(
        <Fragment>
            {price === finalTotal ? (
               <CustomLabel style={{...priceStyle}}>{price}</CustomLabel>
            ) : finalTotal === "0" ? (
                <CustomLabel style={{...priceStyle}}>{price}</CustomLabel>
            ) : (
                <div>
                    <CustomLabel style={{fontWeight: "600",color: primaryOrange,marginRight: "2%", ...finalTotalStyle}}>${finalTotal}</CustomLabel>
                    <CustomLabel style={{textDecoration: "line-through", ...priceStyle}}>${price}</CustomLabel>
                </div>
            )}
        </Fragment>
    )
}

export default ProductPrice;