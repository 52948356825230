import { Form, Modal } from "react-bootstrap";
import CustomLabel from "../shared/label/custom-label.component";
import { neturalBlack } from "../../utils/colors";
import { useEffect, useState } from "react";
import { City, Country, State } from "country-state-city";
import { Typeahead } from "react-bootstrap-typeahead";
import CustomButton from "../button/button.component";

const ServiceLocationModal = (props) => {
  const { serviceLocationArray } = props;
  const [allCountry, setAllCountry] = useState(
    serviceLocationArray?.country?.length > 0
      ? serviceLocationArray?.country
      : []
  );
  const [state, setState] = useState([]);
  const [selectedState, setSelectedState] = useState(
    serviceLocationArray?.state?.length > 0 ? serviceLocationArray?.state : []
  );
  const [city, setCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState(
    serviceLocationArray?.city?.length > 0 ? serviceLocationArray?.city : []
  );
  let changeCountry = false;

  const getState = () => {
    if (allCountry.length !== 0) {
      setState(State.getStatesOfCountry(allCountry[0].isoCode));
    }
  };
  const getCity = () => {
    if (!changeCountry) {
      if (selectedState.length !== 0) {
        setCity(
          City.getCitiesOfState(allCountry[0].isoCode, selectedState[0].isoCode)
        );
      }
    }
  };

  useEffect(() => {
    if (selectedState.length !== 0) {
      getCity();
    } else {
      setSelectedCity([]);
    }
  }, [selectedState]);

  useEffect(() => {
    if (allCountry.length !== 0) {
      getState();
    } else {
      setSelectedState([]);
      setSelectedCity([]);
      changeCountry = true;
    }
  }, [allCountry, changeCountry]);
  return (
    <Modal {...props} fullscreen className="filter-mobile-modal">
      <div
        style={{
          padding: "3%",
          borderBottom: "1px solid #dadada",
        }}
      >
        <Modal.Header
          closeButton
          style={{
            borderBottom: "0px solid rgb(0, 0, 0, 0.1)",
            paddingLeft: "3%",
            paddingRight: "3%",
            backgroundColor: "white",
            borderRadius: "12px",
            paddingBottom: "0px",
            width: "100%",
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            <CustomLabel
              style={{
                color: neturalBlack,
              }}
              className="filter-mobile-modal-header-text"
            >
              Location
            </CustomLabel>
          </Modal.Title>
        </Modal.Header>
        <div style={{ padding: "3%", height: "88vh", overflowX: "auto" }}>
          <Modal.Body
            style={{
              marginBottom: "2%",
              paddingLeft: "3%",
              paddingRight: "3%",
              paddingTop: "1%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                paddingLeft: "3%",
                paddingRight: "3%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ marginTop: "5%" }}>
                <Form.Group controlId="validationCustom01">
                  <Form.Label>Country / Region</Form.Label>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={setAllCountry}
                    options={Country.getAllCountries()}
                    placeholder="Country / Region"
                    selected={allCountry}
                    inputProps={{ required: true }}
                  />
                  <Form.Control.Feedback>
                    Please select a valid Country / Region.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div style={{ marginTop: "8%" }}>
                <Form.Group controlId="validationCustom01">
                  <Form.Label>State / Province</Form.Label>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={setSelectedState}
                    options={state}
                    placeholder="State / Province"
                    selected={selectedState}
                    disabled={allCountry.length === 0 ? true : false}
                    inputProps={{ required: true }}
                  />
                  <Form.Control.Feedback>
                    Please select a valid State / Province.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div style={{ marginTop: "8%" }}>
                <Form.Group controlId="validationCustom01">
                  <Form.Label>City</Form.Label>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={setSelectedCity}
                    options={city}
                    placeholder="City"
                    selected={selectedCity}
                    disabled={selectedState.length === 0 ? true : false}
                    inputProps={{ required: true }}
                  />
                  <Form.Control.Feedback>
                    Please select a valid City.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div style={{ marginTop: "10%" }}>
                <CustomButton
                  title={"Save"}
                  handleButtonClick={() => {
                    props.onHide();
                    props.handleLocationChange(
                      allCountry,
                      selectedState,
                      selectedCity
                    );
                  }}
                />
              </div>
            </div>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default ServiceLocationModal;
