import { gql } from "@apollo/client";

export const FETCH_ALL_SHIPPING_ADDRESS = gql`
  query fetchAllShippingAddress {
    fetchAllShippingAddress {
      address
      firstName
      lastName
      email
      unitNumber
      activeAddress
      key
    }
  }
`;

export const ADD_SHIPPING_ADDRESS = gql`
  mutation addShippingAddress($id: String, $shippingAddress: ShippingAddress) {
    addShippingAddress(id: $id, shippingAddress: $shippingAddress) {
      success
    }
  }
`;

export const UPDATE_SHIPPING_ADDRESS = gql`
  mutation updateShippingAddress(
    $id: String
    $shippingAddress: ShippingAddress
    $index: Int
  ) {
    updateShippingAddress(
      id: $id
      shippingAddress: $shippingAddress
      index: $index
    ) {
      success
    }
  }
`;

export const SET_ACTIVE_SHIPPING_ADDRESS = gql`
  mutation setCurrentShippingAddress($id: String, $index: Int) {
    setCurrentShippingAddress(id: $id, index: $index) {
      success
    }
  }
`;
