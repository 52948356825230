import { useEffect } from "react";
import { Form } from "react-bootstrap";

const ProductVariant = ({ product, selectedOptions, setSelectedOptions }) => {
  useEffect(() => {
    // Set default options when the component mounts
    const defaultOpts = {};
    product?.variants?.forEach((variant) => {
      defaultOpts[variant.variantId] = variant.variantOptions[0];
    });
    setSelectedOptions(defaultOpts);
  }, [product?.variants]);

  const handleVariantChange = (e, variantId) => {
    const { value } = e.target;
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [variantId]: value,
    }));
  };

  return (
    <div>
      {product?.variants?.map((variant, index) => (
        <Form.Group key={index} className="variant-box-style mt-3">
          <Form.Label>{variant.variantType}</Form.Label>
          <Form.Select
            style={{
              borderRadius: "12px",
              border: "1px solid #dadada",
            }}
            value={selectedOptions[variant.variantId]}
            onChange={(e) => handleVariantChange(e, variant.variantId)}
          >
            {variant?.variantOptions?.map((option, optionIndex) => (
              <option
                key={optionIndex}
                style={{ fontSize: "16px" }}
                value={option}
              >
                {option}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      ))}
    </div>
  );
};

export default ProductVariant;
