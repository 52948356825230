import { Col, Row } from "react-bootstrap";
import PersonalDetialForm from "../personal-detail-form/personal-detail-form.component";
import AddressForm from "../address-form-design/address-form-design.component";
import { useEffect, useState } from "react";
import { netural400, neturalBlack, primaryOrange } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";
import { MdOutlineAdd } from "react-icons/md";
import CustomButton from "../button/button.component";
import EditAddressForm from "../edit-address-form/edit-address-form.component";
import AddressList from "../address-list-design/address-list-design.component";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ADD_SHIPPING_ADDRESS,
  FETCH_ALL_SHIPPING_ADDRESS,
  SET_ACTIVE_SHIPPING_ADDRESS,
  UPDATE_SHIPPING_ADDRESS,
} from "../../graphQL/address/addressquery.";
import { useLocation, useNavigate } from "react-router";
import {
  isUserLoggedIn,
  selectCurrentUser,
} from "../../store/user/user.selector";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentUser } from "../../store/user/user.action";
import { useSearchParams } from "react-router-dom";

const BuyAddress = () => {
  const params = useLocation();
  const [searchParams] = useSearchParams();
  const itemType = searchParams.get("type");
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const isUserLogin = useSelector(isUserLoggedIn);
  const [section2Enabled, setSection2Enabled] = useState(false); // State to control section 2 button
  const [addressList, setAddressList] = useState([]);
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({
    address: "",
    email: "",
    firstName: "",
    lastName: "",
    unitNumber: "",
  });

  useEffect(() => {
    if (isUserLogin && params.state) {
      fetchAllShippingAddress();
    } else {
      navigation("/");
    }
  }, []);

  const [updateShippingAddress] = useMutation(UPDATE_SHIPPING_ADDRESS);
  const [setCurrentShippingAddress] = useMutation(SET_ACTIVE_SHIPPING_ADDRESS);

  const updateSelectedAddress = async (key) => {
    let response = await setCurrentShippingAddress({
      variables: {
        id: currentUser._id,
        index: parseInt(key, 10),
      },
    });
    // console.log("Result-------------",response.data.setCurrentShippingAddress.success)
    // if (response.data.setCurrentShippingAddress.success) {
    // }else{

    // }
  };

  const handleAddressChange = (index, text) => {
    const updatedList = addressList.map((item, idx) => {
      return {
        ...item,
        activeAddress: item.key === index,
      };
    });
    setAddressList(updatedList);

    // Update selectedAddress based on the index
    const selected = updatedList.find((item) => item.activeAddress);
    // console.log("selcted addres--------------", index);
    setSelectedAddress(selected);
    updateSelectedAddress(index);
  };

  useEffect(() => {
    // console.log("selected addres---------", selectedAddress);
    if (editAddress) {
      setSection2Enabled(false);
      setAddNewAddress(false);
    }
  }, [editAddress]);

  const handleSignInClick = (isAddressValid, country) => {
    // console.log("Vald----------------", isAddressValid);
    if (isAddressValid) {
      setSection2Enabled(true); // Enable section 2 button when place is selected
      // verifyInDatabase();
      if (country && addNewAddress) {
        setSelectedAddress({
          ...selectedAddress,
          address: country.address,
          unitNumber: country.unitNumber,
        });
      }
      // console.log(
      //   "Country----------------",
      //   country,
      //   addressList,
      //   selectedAddress
      // );
    }
  };
  const [fetchAllShippingAddress] = useLazyQuery(FETCH_ALL_SHIPPING_ADDRESS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      // console.log("Value-----------", data.fetchAllShippingAddress);
      if (data && data.fetchAllShippingAddress) {
        // setAddNewAddress(false);
        setAddressList(data.fetchAllShippingAddress);
        setSelectedAddress(
          data.fetchAllShippingAddress.find((item) => item.activeAddress)
        );
      } else {
        setAddNewAddress(true);
      }
    },
  });

  const [addShippingAddress] = useMutation(ADD_SHIPPING_ADDRESS);

  const checkChangeData = (personalInfo) => {
    if (addressList && addressList.length > 0) {
      const selectedInfo = addressList.find(
        (info) => info.key === selectedAddress.key
      );
      if (selectedInfo) {
        const { address, firstName, lastName, unitNumber } = selectedInfo;

        if (
          address === selectedAddress.address &&
          firstName === personalInfo.firstName &&
          lastName === personalInfo.lastName &&
          unitNumber === selectedAddress.unitNumber
        ) {
          // console.log("No changes detected.");
          return true;
        } else {
          // console.log("Changes detected.");
          return false;
        }
      }
    }
    // console.log("Selected address not found.");
    return false;
  };

  const handleContinueClick = async (isValid, personalInfo) => {
    if (isValid) {
      const changesDetected = checkChangeData(personalInfo);
      let shippingAddress = {
        unitNumber: selectedAddress.unitNumber,
        firstName: personalInfo.firstName.trim(),
        lastName: personalInfo.lastName.trim(),
        address: selectedAddress.address,
        email: personalInfo.email,
      };
      if (addNewAddress) {
        setSelectedAddress({
          ...selectedAddress,
          firstName: personalInfo.firstName,
          lastName: personalInfo.lastName,
          email: personalInfo.email,
        });
      
        // console.log("Final array----------", shippingAddress, selectedAddress, personalInfo)
        let response = await addShippingAddress({
          variables: {
            id: currentUser._id,
            shippingAddress: shippingAddress,
          },
        });
        if (response.data.addShippingAddress.success) {
          setSection2Enabled(false);
          setAddNewAddress(false);
          setEditAddress(false);
        } else {
          alert("Something went wrong, Please try again later.");
        }
      }

      if (editAddress) {
        if (changesDetected) {
          setSection2Enabled(false);
          setAddNewAddress(false);
          setEditAddress(false);
        } else {
          let response = await updateShippingAddress({
            variables: {
              id: currentUser._id,
              shippingAddress: shippingAddress,
              index: selectedAddress.key,
            },
          });
          // console.log(
          //   "Final value-----------------",
          //   response.data.updateShippingAddress
          // );
          if (response.data.updateShippingAddress.success) {
            setSection2Enabled(false);
            setAddNewAddress(false);
            setEditAddress(false);
            fetchAllShippingAddress();
          } else {
            alert("Something went wrong, Please try again later. Update");
          }
        }
      } else {
        setSelectedAddress(addressList.find((item) => item.activeAddress));

        const updateReduxData ={
          email: addNewAddress?personalInfo.email:currentUser.email,
          shippingAddress,
        }
        dispatch(updateCurrentUser(updateReduxData));
        // console.log("Check-1------------", selectedAddress);
        navigation(`checkout?type=${itemType}`, { state: params.state });
      }
    }
  };

  const handleEditAddress = (isValid, updatedAddress) => {
    let convertAddress =
      updatedAddress.address +
      ", " +
      updatedAddress.shippingCityName +
      ", " +
      updatedAddress.shippingProvince +
      " " +
      updatedAddress.shippingPostalCode +
      ", " +
      updatedAddress.shippingCountry;
    setSelectedAddress({
      ...selectedAddress,
      address: convertAddress,
      unitNumber: updatedAddress.unitNumber,
    });
    setSection2Enabled(true);
  };

  return (
    <div style={{ marginTop: "3%", width: "99%" }}>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Col xl={4} lg={4} md={10} sm={10} xs={10}>
          {/* Section 1 */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CustomLabel
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: neturalBlack,
                width: "100%",
              }}
            >
              Shipping address
            </CustomLabel>
            <CustomLabel
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: netural400,
              }}
            >
              We’ll use this information to better assist in your shipment
              options.
            </CustomLabel>
            {addressList.length === 0 ? (
              <AddressForm
                handleSignInClick={handleSignInClick}
                section2Enabled={section2Enabled}
                buttonTitle={"Next"}
              />
            ) : addNewAddress ? (
              <AddressForm
                handleSignInClick={handleSignInClick}
                section2Enabled={section2Enabled}
                buttonTitle={"Next"}
              />
            ) : editAddress ? (
              <EditAddressForm
                handleEditAddress={handleEditAddress}
                section2Enabled={section2Enabled}
                buttonTitle={"Next"}
                selectedAddress={selectedAddress}
              />
            ) : (
              <div style={{ marginTop: "5%" }}>
                {addressList.map((label, index) => (
                  <AddressList
                    key={index}
                    label={label}
                    index={index}
                    setEditAddress={setEditAddress}
                    handleAddressChange={handleAddressChange}
                  />
                ))}
                {addressList.length < 3 && (
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                    onClick={() => {
                      setAddNewAddress(true);
                      setSection2Enabled(false);
                      setEditAddress(false);
                      setSelectedAddress({});
                    }}
                  >
                    <MdOutlineAdd color={primaryOrange} size={24} />
                    <CustomLabel
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: primaryOrange,
                        marginLeft: "1%",
                      }}
                    >
                      Add new address
                    </CustomLabel>
                  </div>
                )}
                {!section2Enabled && (
                  <div style={{ marginTop: "5%" }}>
                    <CustomButton
                      title={"Next"}
                      // processing={isLoading}
                      buttonType={"validation"}
                      isValid={true}
                      handleButtonClick={() => {
                        handleSignInClick(true);
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </Col>
        <Col xl={1} lg={1} md={1} sm={1} xs={1}></Col>
        <Col xl={4} lg={4} md={10} sm={10} xs={10}>
          {/* Section 2 */}
          <PersonalDetialForm
            handleContinueClick={handleContinueClick}
            section2Enabled={section2Enabled}
            selectedAddress={selectedAddress}
            editAddress={editAddress}
            addNewAddress={addNewAddress}
          />
        </Col>
      </Row>
    </div>
  );
};

export default BuyAddress;
