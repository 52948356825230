import { Modal } from "react-bootstrap";
import { netural400, neturalBlack } from "../../utils/colors";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useEffect, useState } from "react";
import CustomButton from "../button/button.component";
import SignupModal from "../signup-modal/signup-modal.component";
import VerifyOTPModal from "../verify-otp-modal/verify-otp-modal.component";
import { useLazyQuery } from "@apollo/client";
import { LOGIN_CHECK } from "../../graphQL/auth/auth.query";
import { toast } from "react-toastify";
import {
  generateRecaptcha,
  signInWithUserPhoneNumber,
} from "../../utils/firebase/firebase.utils";
import CustomLabel from "../shared/label/custom-label.component";

const LoginModal = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneValid, setPhoneValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formattedNumber, setFormattedNumber] = useState("");
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState("");
  const [actionType, setActionType] = useState("login");
  const [userInfo, setUserInfo] = useState({});

  const [loginCheck] = useLazyQuery(LOGIN_CHECK);

  useEffect(() => {
    // Reset input field data when the modal is closed
    return () => {
      setPhoneNumber("");
      setPhoneValid(false);
      setActionType("login");
      setUserInfo({});
    };
  }, [props.show]);

  const onSignIn = async (appVerifier) => {
    // console.log("onSignInmethod", appVerifier);
    try {
      const confirmation = await signInWithUserPhoneNumber(
        phoneNumber,
        appVerifier
      );
      setConfirmationResult(confirmation);
      setIsLoading(false);
      setShowOTPModal(true);
      props.onHide();
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const verifyInDatabase = async () => {
    try {
      setIsLoading(true);
      const { data } = await loginCheck(
        {
          variables: {
            phone: phoneNumber,
            type: "login",
          },
        },
        {
          fetchPolicy: "no-cache",
        }
      );
      const result = data.loginCheck;
      if (result && result.completed) {
        const recaptcha = generateRecaptcha();
        if (recaptcha) {
          onSignIn(recaptcha);
        }
      } else {
        setIsLoading(false);
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleSignInClick = () => {
    if (isPhoneValid) {
      verifyInDatabase();
    }
  };

  const handleSignUpClick = () => {
    setShowSignUpModal(true);
    props.onHide();
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className="login-modal-style"
      >
        <div style={{ padding: "3%" }}>
          <Modal.Header
            closeButton
            style={{
              borderBottom: "0px solid rgb(0, 0, 0, 0.1)",
              paddingLeft: "3%",
              paddingRight: "3%",
              backgroundColor: "white",
              borderRadius: "12px",
              paddingBottom: "0px",
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <CustomLabel
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: neturalBlack,
                }}
              >
                Login
              </CustomLabel>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              marginBottom: "2%",
              paddingLeft: "3%",
              paddingRight: "3%",
              paddingTop: "1%",
            }}
          >
            <div style={{ marginBottom: "3%" }}>
              <CustomLabel style={{ color: netural400 }}>
                {props.title
                  ? props.title
                  : "Sign in to website with the phone number registered."}
              </CustomLabel>
            </div>
            <div>
              <CustomLabel
                style={{ width: "100%", marginTop: "3%", marginBottom: "2%" }}
              >
                Phone number
              </CustomLabel>
              <PhoneInput
                country={"ca"}
                value={phoneNumber}
                enableSearch
                onChange={(value) => {
                  if (value && isValidPhoneNumber(value)) {
                    setPhoneNumber(value);
                    setFormattedNumber(value);
                    setPhoneValid(true);
                  } else {
                    setPhoneValid(false);
                  }
                }}
                international
                defaultCountry="CA"
              />
            </div>
            <div
              style={{
                marginTop: "8%",
                marginBottom: "8%",
              }}
            >
              <CustomButton
                title={"Log in"}
                processing={isLoading}
                buttonType={"validation"}
                isValid={isPhoneValid}
                handleButtonClick={handleSignInClick}
              />
            </div>
            <div style={{ borderTop: "1px solid #dadada" }}>
              <div
                style={{
                  marginTop: "8%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CustomLabel
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: neturalBlack,
                  }}
                >
                  Sign up
                </CustomLabel>
                <CustomLabel
                  style={{
                    marginTop: "2%",
                    color: netural400,
                  }}
                >
                  It's quick and easy to set up your account.
                </CustomLabel>
              </div>
              <div style={{ marginTop: "3%" }}>
                <CustomButton
                  title={"Create account"}
                  buttonType={"inverted"}
                  isValid={isPhoneValid}
                  handleButtonClick={handleSignUpClick}
                />
              </div>
            </div>
          </Modal.Body>
        </div>
        <div id="recaptcha-container"></div>
      </Modal>
      {showSignUpModal && (
        <SignupModal
          show={showSignUpModal}
          onHide={() => setShowSignUpModal(false)}
          handleSignInClick={() => {
            setShowSignUpModal(false);
            props.handleSignInClickLogin();
          }}
          handleSignUpClick={(number, appVerifier, actionType, userInfo) => {
            setUserInfo(userInfo);
            setActionType(actionType);
            setConfirmationResult(appVerifier);
            setFormattedNumber(number);
            setShowOTPModal(true);
            setShowSignUpModal(false);
          }}
        />
      )}
      {showOTPModal && (
        <VerifyOTPModal
          show={showOTPModal}
          actionType={actionType}
          userInfo={userInfo}
          formattedNumber={formattedNumber}
          confirmationResult={confirmationResult}
          onHide={() => setShowOTPModal(false)}
        />
      )}
    </>
  );
};

export default LoginModal;
