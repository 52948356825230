import React, { useRef, useEffect } from "react";
import { FloatingLabel, Form } from "react-bootstrap";

function AutocompleteCountryInput({ onPlaceSelected }) {
  const inputRef = useRef(null);
  let autocomplete = null;

  useEffect(() => {
    if (inputRef.current) {
      autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["(regions)"], // Limit autocomplete to countries
        }
      );

      autocomplete.addListener("place_changed", handlePlaceSelect);
    }

    return () => {
      if (autocomplete) {
        autocomplete.unbindAll();
        autocomplete = null;
      }
    };
  }, []);

  const handlePlaceSelect = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
    //   console.log("Place------------", place)
      if (place && place.address_components) {
        const country = place.address_components.find((component) =>
          component.types.includes("country")
        );
        if (country) {
          onPlaceSelected(country); // Pass the country code to the callback
        }
      }
    }
  };

  return (
    <FloatingLabel controlId="floatingInput" label="Country">
      <Form.Control
        ref={inputRef}
        type="name"
        className="pt-3"
        style={{ borderRadius: "12px", paddingTop: "4%" }}
        placeholder="Canada"
      />
    </FloatingLabel>
  );
}

export default AutocompleteCountryInput;
