import { IoCheckmarkCircle } from "react-icons/io5";
import { neturalBlack, successGreen } from "../../utils/colors";

const CartToastDesign = ({ productImageURL, productName, productPrice }) => {
  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <IoCheckmarkCircle color={successGreen} size={25} />
        <label
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: neturalBlack,
            marginLeft: "2%",
          }}
        >
          Successfully added to Cart
        </label>
      </div>
      <div style={{ display: "flex", flexDirection: "row", marginTop: "5%" }}>
        <img
          src={`${process.env.REACT_APP_HOST_URL}${productImageURL}`}
          alt="Profile"
          style={{ borderRadius: "8px", width: "35%" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "5%",
          }}
        >
          <label
            style={{
              fontSize: "14px",
              fontWeight: "500",
              color: neturalBlack,
              width: "100%",
            }}
          >
            {productName}
          </label>
          <label
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: neturalBlack,
            }}
          >
            ${productPrice}
          </label>
        </div>
      </div>
    </>
  );
};

export default CartToastDesign;
