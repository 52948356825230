import { gql } from "@apollo/client";

export const LOGIN_CHECK = gql`
  query loginCheck($phone: String!, $type: String!) {
    loginCheck(phone: $phone, type: $type) {
      _id
      completed
      message
    }
  }
`;

export const LOGIN = gql`
  mutation login($phone: String!, $notificationToken: String, $type: String) {
    login(phone: $phone, notificationToken: $notificationToken, type: $type) {
      _id
      notificationToken
      name
      email
      username
      country
      avatarURL
      businessAccount
      subscription {
        subscriptionType
        subscription_id
        subscriptionDiscount
        stripeSubscriptionId
        subscriptionEnd
        subscriptionEndDate
        downgraded
        rank
        profile
        duration
      }
      admin
      firstLogin
    }
  }
`;

export const SIGN_UP_CHECK = gql`
  query signUpCheck(
    $ofAge: Boolean!
    $agreeToTerms: Boolean!
    $phone: String!
    $name: String!
  ) {
    signUpCheck(
      ofAge: $ofAge
      agreeToTerms: $agreeToTerms
      phone: $phone
      name: $name
    ) {
      _id
      completed
      message
    }
  }
`;

export const CREATE_USER = gql`
  mutation signUp(
    $name: String
    $phone: String!
    $country: String
    $notificationToken: String
    $referrerId: String
    $email: String
  ) {
    signUp(
      name: $name
      phone: $phone
      country: $country
      notificationToken: $notificationToken
      referrerId: $referrerId
      email: $email
    ) {
      _id
      notificationToken
    }
  }
`;