import { createRef, useEffect, useRef, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { IoSearch } from "react-icons/io5";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { netural400, neturalBlack } from "../../utils/colors";
import { useLocation, useNavigate } from "react-router-dom";
import ProductPrice from "../product-price/product-price.component";
import { MdClear } from "react-icons/md";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import { FETCH_PRODUCT_WITH_FILTER_SORT } from "../../graphQL/products/products";
import { useLazyQuery } from "@apollo/client";
import { FETCH_SERVICES_WITH_FILTER_SORT } from "../../graphQL/services/services";
import { selectCart } from "../../store/cart/cart.selector";

const SearchBar = () => {
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedType, setSelectedType] = useState("Products");
  const [totalCount, setTotalCount] = useState(0);
  const navigation = useNavigate();
  const ref = createRef();
  const [clickedViewAll, setClickedViewAll] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const typeaheadRef = useRef(null);
  const params = useLocation();

  useEffect(() => {
    if (searchInput && selectedType === "Products") {
      fetchProductsWithFilterAndSort({
        variables: {
          filterBy: {},
          sortBy: "",
          searchedKeyword: searchInput,
        },
      });
    } else if (searchInput && selectedType === "Services") {
      fetchServicesWithFilterAndSort({
        variables: {
          filterBy: {},
          sortBy: "",
          searchedKeyword: searchInput,
        },
      });
    }
  }, [searchInput]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [fetchProductsWithFilterAndSort, { loading: productListLoading }] =
    useLazyQuery(FETCH_PRODUCT_WITH_FILTER_SORT, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.fetchProductsWithFilterAndSort) {
          const searchProduct = data.fetchProductsWithFilterAndSort;
          setProducts(searchProduct.products || []);
          setTotalCount(searchProduct.totalCount);
        } else {
          setProducts([]);
          setTotalCount(0);
        }
      },
    });

  const [fetchServicesWithFilterAndSort, { loading: serviceListLoading }] =
    useLazyQuery(FETCH_SERVICES_WITH_FILTER_SORT, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.fetchServicesWithFilterAndSort) {
          const searchService = data.fetchServicesWithFilterAndSort;
          // console.log("Service--------", searchService);
          setServices(searchService.services);
          setTotalCount(searchService.totalCount);
        } else {
          setServices([]);
          setTotalCount(0);
        }
      },
    });

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  const handleClickOutside = (e) => {
    if (typeaheadRef.current && !typeaheadRef.current.contains(e.target)) {
      setClickedViewAll(false);
    }
  };

  const handleSelectChange = (event) => {
    handleTypeChange(event.target.value);
  };
  const handleInputChange = (input) => {
    const searchedText = input.trim();
    if (searchedText !== "") {
      setClickedViewAll(true);
    } else {
      setClickedViewAll(false);
    }
    setSearchInput(searchedText);
  };

  const handleProductListWithSearch = () => {
    if (searchInput.trim() != "") {
      setClickedViewAll(false);
      if (selectedType === "Products") {
        navigation(`/products/search/${encodeURIComponent(searchInput)}`);
      } else if (selectedType === "Services") {
        navigation(`/services/search/${encodeURIComponent(searchInput)}`);
      } else {
        navigation("/");
      }
    }
  };

  return (
    <div
      style={{
        borderBottom: "1px solid #dadada",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "3%",
          width: "100%",
        }}
      >
        {/* <Col lg={12} md={12} sm={12} xs={12}> */}
        <Col xl={1} lg={2} md={2} sm={4} xs={4} style={{ paddingRight: "0px" }}>
          <Form.Group style={{ color: "#A6A6A6" }}>
            <Form.Select
              aria-label="Default select example"
              size="sm"
              style={{
                borderTopLeftRadius: "12px",
                borderBottomLeftRadius: "12px",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                borderLeft: "1px solid #dadada",
                borderTop: "1px solid #dadada",
                borderBottom: "1px solid #dadada",
                borderRight: "1px solid #dadada",
                fontSize: "1rem",
              }}
              value={selectedType}
              onChange={handleSelectChange}
            >
              <option value="Products">Products</option>
              <option value="Services">Services</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xl={5} lg={6} md={6} sm={8} xs={8} style={{ paddingLeft: "0px" }}>
          <InputGroup
            style={{
              color: "#A6A6A6",
              borderTopRightRadius: "12px",
              borderBottomRightRadius: "12px",
              borderLeft: "0px solid #dadada",
              borderTop: "1px solid #dadada",
              borderBottom: "1px solid #dadada",
              borderRight: "1px solid #dadada",
              display: "flex",
              alignItems: "center",
              paddingRight: "2%",
            }}
          >
            <Typeahead
              ref={ref}
              id="custom-filtering-example"
              labelKey={(option) => `${option.title}`}
              options={
                Array.isArray(
                  selectedType === "Products"
                    ? products
                    : selectedType === "Services"
                    ? services
                    : []
                )
                  ? selectedType === "Products"
                    ? products
                    : selectedType === "Services"
                    ? services
                    : []
                  : []
              }
              placeholder="Search by name, type, etc..."
              maxResults={4}
              paginationText={`View all results [${totalCount}]`}
              open={clickedViewAll}
              filterBy={() => true}
              isLoading={
                selectedType === "Products"
                  ? productListLoading
                  : serviceListLoading
              }
              emptyLabel={
                (selectedType === "Products" && productListLoading) ||
                (selectedType === "Services" && serviceListLoading)
                  ? "Searching...."
                  : "No item found."
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  const searchedText = e.target.value.trim();
                  if (searchedText.length > 0) {
                    setSearchInput(searchedText);
                    setClickedViewAll(false);
                    if (selectedType === "Products") {
                      navigation(
                        `/products/search/${encodeURIComponent(searchedText)}`
                      );
                    } else if (selectedType === "Services") {
                      navigation(
                        `/services/search/${encodeURIComponent(searchedText)}`
                      );
                    } else {
                      navigation("/");
                    }
                  }
                }
              }}
              onBlur={() => {
                setClickedViewAll(false);
              }}
              onPaginate={handleProductListWithSearch}
              onInputChange={handleInputChange}
              renderMenuItemChildren={(option) => {
                return  (
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Row
                        style={{ padding: "1%" }}
                        onClick={() => {
                          setClickedViewAll(false);
                          const encryptedId = btoa(option._id);
                          if (selectedType === "Products") {
                            navigation(`/products/details/${encryptedId}`);
                          } else if (selectedType === "Services") {
                            navigation(`/services/details/${encryptedId}`);
                          }
                        }}
                      >
                        <Col xl={2} lg={4} md={4} sm={5} xs={5}>
                          <img
                            src={`${process.env.REACT_APP_HOST_URL}${option.medias[0].uri}`}
                            alt={option.name}
                            style={{
                              width: "100%",
                              aspectRatio: "3/2.5",
                              objectFit: "cover",
                              borderRadius: "14px",
                            }}
                            // className="seacrh-image"
                          />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={7} xs={7}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: "2%",
                              paddingRight: "2%",
                              width: "100%",
                            }}
                          >
                            <label
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: neturalBlack,
                              }}
                            >
                              {option.title}
                            </label>
                            <label
                              style={{ fontSize: "12px", color: netural400 }}
                            >
                              {option.companyName}
                            </label>
                            <ProductPrice
                              price={option.price}
                              finalTotal={option.finalTotal}
                              priceStyle={{
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                              finalTotalStyle={{
                                fontSize: "14px",
                                fontWeight: "600",
                                marginTop: "1%",
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "0.5%",
                          marginRight: "0.5%",
                          paddingBottom: "2%",
                          paddingTop: "2%",
                          borderBottom: "1px solid #dadada",
                        }}
                      ></div> */}
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}></Col>
                  </Row>
                );
              }}
            />
            {searchInput != "" ? (
              <MdClear
                onClick={() => {
                  setSearchInput("");
                  ref.current.clear();
                  if (params.pathname.includes(selectedType.toLowerCase())) {
                    navigation(`/${selectedType.toLowerCase()}`);
                  }
                }}
                style={{ marginLeft: "1%", marginRight: "1%" }}
                size={20}
              />
            ) : null}
            <IoSearch
              onClick={() => {
                if (searchInput.trim().length > 0) {
                  if (selectedType === "Products") {
                    navigation(
                      `/products/search/${encodeURIComponent(searchInput)}`
                    );
                  } else if (selectedType === "Services") {
                    navigation(
                      `/services/search/${encodeURIComponent(searchInput)}`
                    );
                  } else {
                    navigation("/");
                  }
                }
              }}
              size={20}
            />
          </InputGroup>
        </Col>
        {/* </Col> */}
      </Row>
    </div>
  );
};
export default SearchBar;
