import { Button } from "react-bootstrap";
import { netural50, neturalBlack } from "../../utils/colors";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import "../button/button.styles.css";
const CustomButton = ({title, buttonType= "default",isValid, handleButtonClick, processing=false, processingText, customStyle ,...props}) =>{
  const getButtonStyle = (type) =>{
    switch (type){
      case "validation":
        return{
          backgroundColor: isValid ? "#E97101" : "#F2F2F2",
          height: "48px",
          width:"100%",
          borderRadius: "12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: isValid ? "white" : "#A6A6A6",
          fontSize: "18px",
          fontWeight: "700",
          borderColor:isValid ? "#E97101" : "#F2F2F2"
        }
      case "inverted":
        return{
          width: "100%",
          height: "42px",
          border: "2px solid #E97101",
          borderRadius: "12px",
          marginTop: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          fontSize: "14px",
          color: "#E97101",
          fontWeight: "700",
        }
      case "secondary":
        return{
          height: "42px",
          width: "100%",
          backgroundColor: netural50,
          borderRadius: "12px",
          padding: "8px",
          fontSize: "14px",
          color: neturalBlack,
          fontWeight: "700",
          borderColor: netural50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
      }
      default:
        return{
          height: "42px",
          width: "100%",
          backgroundColor: "#E97101",
          border: "#E97101",
          borderRadius: "12px",
          marginTop: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: title.toLowerCase()==="submit" || title.toLowerCase().startsWith("save")  ? "700": "normal",
        }
    }
  }
  const buttonStyle = getButtonStyle(buttonType);

  // Merge customStyle with the buttonStyle
  const mergedStyle = {
    ...buttonStyle,
    ...customStyle, // This will overwrite any conflicting styles
  };

    return(
        <Button 
          className="custom-button"  
          {...props}
          style={mergedStyle}
          disabled={buttonType === "validation" || processing ? !isValid || processing : false}
          onClick={handleButtonClick}
        >
          {processing ? (
            <LoadingSpinner text={processingText} />
          ) : title}
        </Button>
    )
}

export default CustomButton;