import { Modal } from "react-bootstrap";

const DownloadAppModal = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Body style={{ marginBottom: "2%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="../../images/FinalLogo.svg"
            alt="logo"
            style={{ width: "10%" }}
          />
          <label style={{ fontSize: "24px", fontWeight: "600" }}>
            Download Free App!
          </label>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "4%",
          }}
        >
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              paddingRight: "10%",
              alignItems: "center",
            }}
          >
            <label style={{ fontSize: "24px", fontWeight: "600" }}>
              For Android
            </label>
            <img
              src="../../images/google_play_store _1.png"
              alt="logo"
              style={{ width: "100%" }}
            />
          </div>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              paddingLeft: "10%",
              alignItems: "center",
            }}
          >
            <label style={{ fontSize: "24px", fontWeight: "600" }}>
              For iOS
            </label>
            <img
              src="../../images/apple_store_1.png"
              alt="logo"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DownloadAppModal;
