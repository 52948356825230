import { Col, Row } from "react-bootstrap";
import { MdArrowRightAlt } from "react-icons/md";
import { netural400 } from "../../utils/colors";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import ProductPrice from "../product-price/product-price.component";
import CustomLabel from "../shared/label/custom-label.component";
import "./product-service-viewall-home.style.css";

const ProductServiceViewAllHome = ({ products, title }) => {
  const navigation = useNavigate();
  return (
    <div style={{ width: "85%", marginLeft: "7.5%", marginTop: "5%" }}>
      <Row>
        <Col xl={9} lg={9} md={9} sm={6} xs={6}>
          <CustomLabel
            style={{
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            {title}
          </CustomLabel>
        </Col>
        <Col
          xl={3}
          lg={3}
          md={3}
          sm={6}
          xs={6}
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
          onClick={() => {
            navigation(`/products/category/Pets and Animals`);
          }}
        >
          <div className="view-all-home-page">
            <CustomLabel
              style={{
                textAlign: "center",
                fontSize: "14px",
                color: netural400,
              }}
            >
              View all
            </CustomLabel>
            <MdArrowRightAlt
              size={20}
              style={{ marginLeft: "5%" }}
              color={netural400}
            />
          </div>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "1%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {products.map((productList, index) => {
          const encryptedId = btoa(productList._id);
          return (
            <Col
              xl={3}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              key={index}
              style={{ marginTop: "2%" }}
            >
              <Link
                to={`/products/details/${encryptedId}`}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textDecoration: "none",
                }}
              >
                <img
                  alt=""
                  src={`${process.env.REACT_APP_HOST_URL}${productList.medias[0].uri}`}
                  // src={'./images/Product-Image.png'}
                  className="product-service-image-height"
                />
                <CustomLabel
                  style={{
                    fontWeight: "600",
                    marginTop: "2%",
                  }}
                >
                  {productList.title}
                </CustomLabel>
                <CustomLabel
                  style={{
                    fontSize: "12px",
                    color: netural400,
                  }}
                >
                  {productList.companyName}
                </CustomLabel>
                <CustomLabel
                  style={{
                    fontSize: "12px",
                    color: netural400,
                  }}
                >
                  {productList.route}
                </CustomLabel>
                <ProductPrice
                  price={productList.price}
                  finalTotal={productList.finalTotal}
                />
              </Link>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default ProductServiceViewAllHome;
