import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { netural400, neturalBlack } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";

const OrderSummaryPricing = ({
  itemType,
  pricing,
  selectedCheckoutCart,
  promotionalCode,
}) => {
  const { subTotal, couponCode, discountTotal, taxAmount, finalCheckoutTotal } =
    pricing;
  const currentUser = useSelector(selectCurrentUser);
  return (
    <div
      style={{
        marginTop: "5%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <CustomLabel
          style={{
            color: netural400,
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          Subtotal
        </CustomLabel>
        <CustomLabel
          style={{
            color: netural400,
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          $ {subTotal}
        </CustomLabel>
      </div>
      {couponCode > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "2%",
          }}
        >
          <CustomLabel
            style={{
              color: netural400,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Coupon Discount:{" "}
            {promotionalCode.type === "percentage"
              ? promotionalCode.discountPercentage + " %"
              : promotionalCode.discountedAmount + " $"}
          </CustomLabel>
          <CustomLabel
            style={{
              color: netural400,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            -$ {couponCode}
          </CustomLabel>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "2%",
        }}
      >
        <CustomLabel
          style={{
            color: netural400,
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          ARPs {currentUser.subscription.subscriptionDiscount}%
        </CustomLabel>
        <CustomLabel
          style={{
            color: netural400,
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          -$ {discountTotal}
        </CustomLabel>
      </div>
      {itemType === "product" && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "2%",
          }}
        >
          <CustomLabel
            style={{
              color: netural400,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Shipping
          </CustomLabel>
          <CustomLabel
            style={{
              color: netural400,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            $ {selectedCheckoutCart.shippingCost}
          </CustomLabel>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "2%",
        }}
      >
        <CustomLabel
          style={{
            color: netural400,
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          Tax {selectedCheckoutCart.taxRate}%
        </CustomLabel>
        <CustomLabel
          style={{
            color: netural400,
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          $ {taxAmount}
        </CustomLabel>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "2%",
        }}
      >
        <CustomLabel
          style={{
            color: neturalBlack,
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          Total
        </CustomLabel>
        <CustomLabel
          style={{
            color: neturalBlack,
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          $ {finalCheckoutTotal}
        </CustomLabel>
      </div>
    </div>
  );
};

export default OrderSummaryPricing;
