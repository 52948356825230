import Calendar from "react-calendar";
import "../custom-calendar/custom-calendar.styles.css";

const CustomCalendar = ({ selectedDate, onChangeDate }) => {
  return (
    <div>
      <Calendar value={selectedDate} onChange={onChangeDate} />
    </div>
  );
};

export default CustomCalendar;
