import { ApolloClient, InMemoryCache, createHttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import jwt_decode from "jwt-decode";
import { AES, enc } from "crypto-js";
import { auth } from "../firebase";
import { toast } from "react-toastify";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_HOST_URL}/graphql`, // Your backend GraphQL endpoint
});

const authLink = setContext(async (_, { headers }) => {
  let accessToken = getTokenFromLocalStorage();

  // Check if the token has expired
  const tokenExpired = accessToken ? isTokenExpired(accessToken) : true;

  if (tokenExpired) {
    // Regenerate the token
    accessToken = await regenerateToken();
  }

  return {
    headers: {
      ...headers,
      authorization: accessToken ? accessToken : "",
    },
  };
});

const encryptToken = (token) => {
  const encryptedToken = AES.encrypt(
    token,
    process.env.REACT_APP_ENCRYPTION_KEY
  ).toString();
  return encryptedToken;
};

const decryptToken = (encryptedToken) => {
  const decryptedToken = AES.decrypt(
    encryptedToken,
    process.env.REACT_APP_ENCRYPTION_KEY
  ).toString(enc.Utf8);
  return decryptedToken;
};

const getTokenFromLocalStorage = () => {
  const encryptedToken = localStorage.getItem("accessToken");
  if (encryptedToken) {
    return decryptToken(encryptedToken);
  }
  return null;
};

const isTokenExpired = (token) => {
  if (!token) {
    return true;
  }

  const decodedToken = jwt_decode(token);
  const currentTime = Math.floor(Date.now() / 1000); // Get current timestamp

  return decodedToken.exp < currentTime;
};

export const regenerateToken = async () => {
  const user = auth.currentUser;
  if (user) {
    try {
      const token = await user.getIdToken();
      let decodedToken = jwt_decode(token);
      let encryptedAuthTime = AES.encrypt(
        decodedToken.auth_time.toString(),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString();
      localStorage.setItem("expiryTime", encryptedAuthTime);
      localStorage.setItem("isAuthenticated", "true");
      const encryptedToken = encryptToken(token);
      localStorage.setItem("accessToken", encryptedToken);
      return token;
    } catch (error) {
    //  console.log("Error regenerating token:", error);
    }
  } else {
    localStorage.clear();
    return null;
  }
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ extensions ,message, locations, path }) =>{
    if(extensions.code === "UNAUTHENTICATED"){
      toast.error("Authentication Failed: Please login", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
        // Delay and then redirect
        setTimeout(() => {
          window.location.href = "/"
        }, 1000);
    }
    }
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
});

export default client;
