import { createAction } from "../../utils/reducer/reducer.utils";
import { CART_ACTION_TYPES } from "./cart.types";

export const setUserCartCount = (cartCount) =>{
    // console.log('SET USER CART ITEMS', cartCount)
    return createAction(CART_ACTION_TYPES.SET_CART_COUNT, cartCount);   
}
export const setUserCart = (cart) =>{
    // console.log('SET USER CART ITEMS', cart)
    return createAction(CART_ACTION_TYPES.SET_CART, cart);   
}
export const setUserServiceCart = (cart) =>{
  //  console.log('SET USER CART ITEMS', cart)
    return createAction(CART_ACTION_TYPES.SET_SERVICE_CART, cart);   
}
export const resetUserCartCount = () =>{
    return createAction(CART_ACTION_TYPES.RESET_CART_COUNT);   
}
export const resetUserServiceCart = () =>{
    return createAction(CART_ACTION_TYPES.RESET_SERVICE_CART);   
}

export const increaseUserCartCount = (increaseCount) =>{
    return createAction(CART_ACTION_TYPES.INCREASE_CART_COUNT, increaseCount);   
}

export const decreaseUserCartCount = (decreaseCount) =>{
    return createAction(CART_ACTION_TYPES.DECREASE_CART_COUNT,decreaseCount);   
}
