import React from "react";
import { useNavigate } from "react-router";

const Logo = () => {
  const navigation = useNavigate();
  return (
    <div className="logo-container">
      {/* Use the import statement to include your SVG file */}
      <img
        src={process.env.PUBLIC_URL + "/images/FinalLogo.svg"}
        alt="Logo"
        className="logo-image"
        onClick={() => {
          navigation("/");
        }}
      />
    </div>
  );
};

export default Logo;
