import { Modal } from "react-bootstrap";
import { neturalBlack } from "../../utils/colors";
import { Rating } from "react-simple-star-rating";
import { BsDot } from "react-icons/bs";
import ReviewList from "../product-detail-review-list/product-detail-review-list.component";
import { useQuery } from "@apollo/client";
import { FETCH_REVIEWS } from "../../graphQL/products/products";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import { useState } from "react";

const ReviewModal = (props) => {
  const [reviewList, setReviewList] = useState("");
  const { loading, error, data } = useQuery(FETCH_REVIEWS, {
    variables: {
      item_id: props.item_id,
    },
  });
  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingSpinner overlay />
      </div>
    );
  }
  // if (error) return <p>Error: {error.message}</p>;
  if(error) return;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
    >
      <div style={{ padding: "3%" }}>
        <Modal.Header
          closeButton
          style={{
            borderBottom: "0px solid rgb(0, 0, 0, 0.1)",
            paddingLeft: "3%",
            paddingRight: "3%",
            backgroundColor: "white",
            borderRadius: "12px",
            paddingBottom: "0px",
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            <label
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: neturalBlack,
              }}
            >
              Reviews
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            marginBottom: "1%",
            paddingLeft: "3%",
            paddingRight: "3%",
            paddingTop: "3%",
          }}
        >
          <div style={{ marginBottom: "1%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={`${process.env.REACT_APP_HOST_URL}${data?.fetchReviews[0]?.product_image}`}
                alt="Product image"
                style={{
                  width: "20%",
                  height: "18vh",
                  objectFit: "cover",
                  borderRadius: "16px",
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "2%",
              }}
            >
              <Rating
                allowFraction
                size={20}
                initialValue={data?.fetchReviews[0]?.review_rating}
                readonly
                fillColor={neturalBlack}
              />
              <label
                style={{
                  fontSize: "16px",
                  color: neturalBlack,
                  marginLeft: "1%",
                }}
              >
                ({data?.fetchReviews[0]?.review_rating} stars)
              </label>
              <BsDot size={18} />
              <label
                style={{
                  fontSize: "16px",
                  color: neturalBlack,
                  marginLeft: "1%",
                }}
              >
                {props.totalReview} reviews
              </label>
            </div>
            <div
              style={{
                maxHeight: "550px", // Set a max height for the scrollable area
                overflowY: "auto", // Enable vertical scrolling
                marginTop: "10px", // Adjust margin as needed
                scrollbarWidth: "none",
              }}
            >
              <ReviewList reviews={props.reviewList} />
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ReviewModal;
