import { Route, Routes } from "react-router-dom";
import PaymentStatus from "../../components/payment-status/payment-status.component";

const Order = () => {
  return (
    <Routes>
      <Route path="paymentstatus" element={<PaymentStatus />} />
    </Routes>
  );
};

export default Order;
