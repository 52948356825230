import { Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import CustomLabel from "../shared/label/custom-label.component";
import CustomButton from "../button/button.component";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import {
  errorRed,
  netural400,
  neturalBlack,
  neutral200,
  primaryOrange,
  success500,
} from "../../utils/colors";
import "./checkout-page.style.css";
import { useSelector } from "react-redux";
import {
  selectCartCheckoutByBusinessId,
  selectServiceCartCheckoutByBusinessId,
} from "../../store/cart/cart.selector";
import {
  selectCurrentUser,
  selectCurrentUserShippingAddress,
} from "../../store/user/user.selector";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import OrderSummary from "../order-summary/order-summary.component";
import PaymentModal from "../payment-modal/payment-modal.component";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_PAYMENT_INTENT,
  VALIDATE_ORDER_INFORMATION,
} from "../../graphQL/cart/cart.query";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../utils/stripe/stripe.utils";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { VERIFY_AVAILABLE_SLOT_SPOTS } from "../../graphQL/services/services";
import { isEmptyObject } from "../../utils/constants";
import { VALIDATE_PROMOTIONAL_CODE } from "../../graphQL/products/products";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { MdErrorOutline } from "react-icons/md";

const CheckoutPage = () => {
  const params = useLocation();
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const itemType = searchParams.get("type");
  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [service, setService] = useState({});
  const [serviceBookingInfo, setServiceBookingInfo] = useState({});
  const [serviceDurationHour, setServiceDurationHour] = useState(0);
  const [serviceDurationMinute, setServiceDurationMinute] = useState(0);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [stripeOption, setStripeOption] = useState({});
  const [couponCode, setCouponCode] = useState("");
  const [isValidCoupon, setIsValidCoupon] = useState(false);
  const [couponCodeError, setCouponCodeError] = useState("");
  const [promotionalCode, setPromotionalCode] = useState({});

  const [checkountInfo, setCheckoutInfo] = useState({
    subTotal: 0,
    couponCode: 0,
    discountTotal: 0,
    taxAmount: 0,
    finalCheckoutTotal: 0,
  });
  const selectedCheckoutCart = useSelector(
    selectCartCheckoutByBusinessId(params.state)
  );
  const selectedServiceCheckout = useSelector(
    selectServiceCartCheckoutByBusinessId(params.state)
  );
  const userShippingAddress = useSelector(selectCurrentUserShippingAddress);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    // console.log("Checkout-----------------", selectedCheckoutCart, itemType)
    if (!params.state || !itemType) {
      navigation("/");
      return;
    }
    if (
      itemType === "product" &&
      selectedCheckoutCart &&
      !isEmptyObject(selectedCheckoutCart)
    ) {
      setProducts(selectedCheckoutCart.items);
      getCheckoutInfo(selectedCheckoutCart.items);
      setIsLoading(false);
    } else if (
      itemType === "service" &&
      !isEmptyObject(selectedServiceCheckout)
    ) {
      //use service variables
      setService(selectedServiceCheckout);
      setServiceBookingInfo(selectedServiceCheckout.bookingData);
      setServiceDurationHour(selectedServiceCheckout.duration.hours);
      setServiceDurationMinute(selectedServiceCheckout.duration.minutes);
      getServiceCheckoutInfo(selectedServiceCheckout);
      setIsLoading(false);
    } else {
      navigation("/");
    }
  }, [params, itemType]);

  const getSubTotal = (products) => {
    return products
      .reduce(
        (subTotal, item) =>
          subTotal + parseInt(item.quantity) * parseFloat(item.finalTotal),
        0.0
      )
      .toFixed(2);
  };
  const getServiceSubTotal = (service) => {
    return (
      parseInt(service.bookingData.noOfSpots) *
      parseFloat(service.finalTotal).toFixed(2)
    );
  };
  const getDiscountedPrice = (subTotal) => {
    return (
      (subTotal * parseInt(currentUser.subscription.subscriptionDiscount)) /
      100
    ).toFixed(2);
  };
  const getTaxAmount = (taxableTotal) => {
    return (
      (taxableTotal *
        parseFloat(
          itemType === "product"
            ? selectedCheckoutCart?.taxRate
            : selectedServiceCheckout?.taxRate
        )) /
      100
    ).toFixed(2);
  };

  const getFinalTotal = (taxableTotal, taxAmount) => {
    return (taxableTotal + taxAmount).toFixed(2);
  };

  const getCheckoutInfo = (products) => {
    const subTotal = getSubTotal(products);
    const discountedTotal = getDiscountedPrice(parseFloat(subTotal));
    const taxableTotal =
      parseFloat(subTotal) -
      parseFloat(discountedTotal) +
      parseFloat(selectedCheckoutCart?.shippingCost);
    const taxAmount = getTaxAmount(taxableTotal);
    const finalCheckoutTotal = getFinalTotal(
      taxableTotal,
      parseFloat(taxAmount)
    );
    setCheckoutInfo({
      subTotal: subTotal,
      discountTotal: discountedTotal,
      taxAmount: taxAmount,
      finalCheckoutTotal: finalCheckoutTotal,
    });
  };

  const getServiceCheckoutInfo = (service) => {
    const subTotal = getServiceSubTotal(service);
    const discountedTotal = getDiscountedPrice(parseFloat(subTotal));
    const taxableTotal = parseFloat(subTotal) - parseFloat(discountedTotal);
    const taxAmount = getTaxAmount(taxableTotal);
    const finalCheckoutTotal = getFinalTotal(
      taxableTotal,
      parseFloat(taxAmount)
    );
    setCheckoutInfo({
      subTotal: subTotal,
      couponCode: 0,
      discountTotal: discountedTotal,
      taxAmount: taxAmount,
      finalCheckoutTotal: finalCheckoutTotal,
    });
  };

  const [validatePromotionalCode, { loading: promoCodeLoading }] = useLazyQuery(
    VALIDATE_PROMOTIONAL_CODE,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.validatePromotionalCode) {
          let promoCodeValue = data.validatePromotionalCode;
          // console.log("code--------------",promoCodeValue.promotionalCode.type)
          if (promoCodeValue.isValid) {
            delete promoCodeValue.promotionalCode.__typename;
            setPromotionalCode(promoCodeValue.promotionalCode);
            calculatePromoCode(promoCodeValue);
            setIsValidCoupon(!isValidCoupon);
          } else {
            setCouponCodeError(promoCodeValue.message);
          }
          // console.log("all value--------------", promoCodeValue);
        }
      },
      onError: (e) => {
        // console.log("all error--------------", e);
        toast.error(e, {
          position: "top-center",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      },
    }
  );

  const calculatePromoCode = (promoCode) => {
    const newSubtotal =
      checkountInfo.subTotal - promoCode.promotionalCode.discountedAmount;
    const discountedTotal = getDiscountedPrice(parseFloat(newSubtotal));
    const taxableTotal =
      parseFloat(newSubtotal) -
      parseFloat(discountedTotal) +
      parseFloat(selectedCheckoutCart?.shippingCost);
    const taxAmount = getTaxAmount(taxableTotal);
    const finalCheckoutTotal = getFinalTotal(
      taxableTotal,
      parseFloat(taxAmount)
    );
    setCheckoutInfo({
      ...checkountInfo,
      couponCode: promoCode.promotionalCode.discountedAmount,
      discountTotal: discountedTotal,
      taxAmount: taxAmount,
      finalCheckoutTotal: finalCheckoutTotal,
    });
  };

  const handlePromoCodeClick = async () => {
    setCouponCodeError("");
    await validatePromotionalCode({
      variables: {
        promotionalCodeInputs: {
          promotionalCode: couponCode,
          originalAmount: parseFloat(checkountInfo.subTotal),
        },
      },
    });
  };

  const handlePromoCodeCancel = () => {
    // console.log("Calculate---------------",parseFloat(checkountInfo.finalCheckoutTotal) + parseFloat(checkountInfo.couponCode))
    const newSubtotal = parseFloat(
      checkountInfo.subTotal + checkountInfo.couponCode
    );
    const discountedTotal = getDiscountedPrice(parseFloat(newSubtotal));
    const taxableTotal =
      parseFloat(newSubtotal) -
      parseFloat(discountedTotal) +
      parseFloat(selectedCheckoutCart?.shippingCost);
    const taxAmount = getTaxAmount(taxableTotal);
    const finalCheckoutTotal = getFinalTotal(
      taxableTotal,
      parseFloat(taxAmount)
    );
    setCheckoutInfo({
      ...checkountInfo,
      couponCode: 0,
      discountTotal: discountedTotal,
      taxAmount: taxAmount,
      finalCheckoutTotal: finalCheckoutTotal,
    });
    setIsValidCoupon(!isValidCoupon);
  };
  const [createPaymentIntent] =
    useLazyQuery(CREATE_PAYMENT_INTENT, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError: (e) => {
        alert("Something went wrong...", e.message);
        // console.log("ERROR FRONTE END", e);
        setIsPaymentLoading(false)
      },
      onCompleted: async (data) => {
        // console.log("Client Secret", data);
        if (data && data.createPaymentIntent) {
          let paymentInfo = data.createPaymentIntent;
          const options = {
            // passing the client secret obtained in step 3
            clientSecret: paymentInfo.paymentIntent,
            // Fully customizable with appearance API.
            appearance: {
              theme: "stripe",
            variables: {
              colorPrimary: primaryOrange,
              colorBackground: "#ffffff",
              colorText: "#30313d",
              colorDanger: "#df1b41",
              fontFamily: "Ideal Sans, system-ui, sans-serif",
              // spacingUnit: "2px",
              borderRadius: "4px",
              // See all possible variables below
            },
          },
        };
        setStripeOption(options);
        setIsPaymentLoading(false);
        setShowPaymentModal(true);
      }
      setIsPaymentLoading(false);
    },
  });

  const [validateOrder] = useMutation(VALIDATE_ORDER_INFORMATION, {
    onCompleted: (data) => {
      if (data && data.validateOrder.success) {
        initializePaymentRequest();
      } else {
        const validationResponse = data.validateOrder.validationResponse;
        let errorMessage = "";
        if (validationResponse.address != "")
          errorMessage = validationResponse.address;
        else {
          errorMessage =
            validationResponse.itemAvaibility[0]?.availableQuantity == null
              ? validationResponse.itemAvaibility[0].itemName
              : `${validationResponse.itemAvaibility[0].itemName} available  quantity is: ${validationResponse.itemAvaibility[0].availableQuantity}`;
        }
        setIsPaymentLoading(false);
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    },
    onError: (error) => {
      setIsPaymentLoading(false);
      toast.error(error.message, {
        position: "top-center",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    },
  });

  const initializePaymentRequest = async () => {
    let userInfo = {
      email: userShippingAddress.email,
    };
    await createPaymentIntent({
      variables: {
        userInfo,
        price: checkountInfo.finalCheckoutTotal,
      },
    });
  };
  const processProductPurchase = async () => {
    const cartItems = JSON.parse(JSON.stringify(selectedCheckoutCart));
    const itemArray = cartItems.items.map((item) => {
      return {
        itemId: item._id,
        variantCombination: item?.variantCombination
          ? item?.variantCombination
          : null,
        quantity: item.quantity,
        type: "Product",
      };
    });
    await validateOrder({
      variables: {
        address: userShippingAddress.address,
        itemQuantity: itemArray,
      },
    });
  };

  const [verifyAvailableSlotSpots] = useMutation(VERIFY_AVAILABLE_SLOT_SPOTS);

  const processServicePurchase = async () => {
    let result = await verifyAvailableSlotSpots({
      variables: {
        serviceId: service._id,
        selectedDate: serviceBookingInfo.bookingDate,
        selectedStartTime: serviceBookingInfo.serviceSatrtTime,
        selectedEndTime: serviceBookingInfo.serviceEndTime,
        spots: serviceBookingInfo.noOfSpots,
      },
    });
    if (result.data.verifyAvailableSlotSpots.success === true) {
      initializePaymentRequest();
    } else {
      // setAvailabeSlots(result.data.verifyAvailableSlotSpots.remainingCapacity);
      setIsPaymentLoading(false);
      alert(
        "Message: " +
          result.data.verifyAvailableSlotSpots.message +
          "\nAvailable Spots: " +
          result.data.verifyAvailableSlotSpots.remainingCapacity
      );
    }
  };
  const handlePaymentClick = async () => {
    setIsPaymentLoading(true);
    if (itemType === "product") {
      processProductPurchase();
    } else {
      processServicePurchase();
    }
  };
  return (
    <Container style={{ width: "99%" }}>
      <Row className="justify-content-center mt-4">
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="justify-content-center"
          style={{ display: "flex" }}
        >
          <CustomLabel style={{ fontSize: "22px", fontWeight: "bold" }}>
            Checkout
          </CustomLabel>
        </Col>
      </Row>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Row className="justify-content-center">
            <Col md={12} lg={12}>
              <Row
                className="mb-4"
                style={{ display: "flex", justifyContent: "center" }}
              >
                {/* Empty Column */}
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                {/* Content 1 */}
                <Col xs={12} sm={12} md={4} lg={4} xl={4} className="mt-4">
                  {/* Content 1 */}
                  <CustomLabel style={{ fontSize: "18px", fontWeight: "bold" }}>
                    {itemType === "product"
                      ? `Ship to`
                      : `Personal Information`}
                  </CustomLabel>
                  <Card
                    style={{
                      marginTop: "5%",
                      padding: "4%",
                      borderRadius: "12px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <CustomLabel
                        style={{ color: netural400, fontSize: "16px" }}
                      >
                        {`${userShippingAddress.firstName} ${userShippingAddress.lastName}`}
                      </CustomLabel>
                      <CustomLabel
                        onClick={() => {
                          navigation(`/buy?type=${itemType}`, {
                            state: params.state,
                          });
                        }}
                        style={{
                          color: primaryOrange,
                          fontWeight: "600",
                          fontSize: "16px",
                        }}
                      >
                        Edit
                      </CustomLabel>
                    </div>
                    <CustomLabel
                      style={{
                        color: netural400,
                        fontSize: "16px",
                        marginTop: "1%",
                      }}
                    >
                      {userShippingAddress.email}
                    </CustomLabel>
                    <CustomLabel
                      style={{
                        color: netural400,
                        fontSize: "16px",
                        marginTop: "1%",
                      }}
                    >
                      {userShippingAddress.address}
                    </CustomLabel>
                  </Card>
                  {itemType === "service" && (
                    <>
                      <div style={{ marginTop: "8%" }}>
                        <CustomLabel
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          Service method
                        </CustomLabel>
                        <Card
                          style={{
                            marginTop: "3%",
                            padding: "4%",
                            borderRadius: "12px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <CustomLabel
                              style={{
                                color: netural400,
                                fontSize: "16px",
                              }}
                            >
                              {parseInt(serviceDurationHour) >= 1
                                ? serviceDurationHour + " hour"
                                : serviceDurationHour + " hours"}
                              {parseInt(serviceDurationMinute) == 0
                                ? null
                                : " " + serviceDurationMinute + " minutes"}
                              {` - ${serviceBookingInfo.serviceType}`}
                            </CustomLabel>
                          </div>
                        </Card>
                      </div>
                      <div style={{ marginTop: "8%" }}>
                        <CustomLabel
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          Booking date
                        </CustomLabel>
                        <Card
                          style={{
                            marginTop: "3%",
                            padding: "4%",
                            borderRadius: "12px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <CustomLabel
                              style={{
                                color: netural400,
                                fontSize: "16px",
                              }}
                            >
                              {`${service.bookingData.bookingDate} - ${service.bookingData.serviceSatrtTime}`}
                            </CustomLabel>
                          </div>
                        </Card>
                      </div>
                    </>
                  )}
                  {itemType !== "service" && (
                    <div style={{ marginTop: "4%" }}>
                      <CustomLabel
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                      >
                        Coupon code
                      </CustomLabel>
                      <InputGroup
                        style={{
                          color: "#A6A6A6",
                          display: "flex",
                          alignItems: "center",
                          paddingRight: "2%",
                          border: "solid 1px #545454",
                          borderRadius: "12px",
                          marginTop: "2%",
                        }}
                      >
                        <Form.Control
                          required
                          style={{
                            borderRadius: "12px",
                            border: "solid 0px #545454",
                            fontSize: "18px",
                            color: neturalBlack,
                            textTransform: "uppercase",
                          }}
                          type="text"
                          value={couponCode}
                          minLength={3}
                          maxLength={30}
                          disabled={isValidCoupon || promoCodeLoading}
                          onChange={(value) => {
                            const coupon = value.target.value.toUpperCase();
                            if (coupon.trim().length !== 0) {
                              setCouponCode(coupon);
                              setCouponCodeError("");
                            } else {
                              setCouponCodeError("");
                              setCouponCode("");
                            }
                          }}
                        />
                        {promoCodeLoading ? (
                          <LoadingSpinner />
                        ) : (
                          <div
                            style={{ marginLeft: "2%", padding: "1.5%" }}
                            onClick={() => {
                              if (isValidCoupon) {
                                handlePromoCodeCancel();
                              } else {
                                handlePromoCodeClick();
                              }
                            }}
                          >
                            {isValidCoupon ? (
                              <CustomLabel
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  color: primaryOrange,
                                }}
                              >
                                Remove
                              </CustomLabel>
                            ) : (
                              <CustomLabel
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  color: couponCode
                                    ? primaryOrange
                                    : neutral200,
                                }}
                              >
                                Apply
                              </CustomLabel>
                            )}
                          </div>
                        )}
                      </InputGroup>
                      {isValidCoupon && (
                        <CustomLabel
                          style={{
                            fontSize: "12px",
                            color: success500,
                            display: "flex",
                            alignItems: "center",
                            marginTop: "1%",
                            marginLeft: "1%",
                          }}
                        >
                          <IoCheckmarkCircleOutline
                            color={success500}
                            size={14}
                            style={{ marginRight: "1%" }}
                          />
                          Coupon code applied
                        </CustomLabel>
                      )}
                      {couponCodeError && (
                        <CustomLabel
                          style={{
                            fontSize: "12px",
                            color: errorRed,
                            display: "flex",
                            alignItems: "center",
                            marginTop: "1%",
                            marginLeft: "1%",
                          }}
                        >
                          <MdErrorOutline
                            color={errorRed}
                            size={14}
                            style={{ marginRight: "1%" }}
                          />
                          {couponCodeError}
                        </CustomLabel>
                      )}
                    </div>
                  )}
                  <div className="checkout-button-laptop">
                    <CustomButton
                      title={"Continue to payment"}
                      processing={isPaymentLoading}
                      customStyle={{ width: "100%", fontWeight: "bold" }}
                      handleButtonClick={handlePaymentClick}
                    />
                  </div>
                </Col>
                {/* Empty Columns */}
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                {/* Content 2 */}
                <Col xs={12} sm={12} md={5} lg={5} xl={5} className="mt-4">
                  {/* Content 2 */}
                  {itemType === "product" ? (
                    <OrderSummary
                      products={products}
                      itemType={itemType}
                      pricing={checkountInfo}
                      selectedCheckoutCart={selectedCheckoutCart}
                      promotionalCode={promotionalCode}
                    />
                  ) : (
                    <OrderSummary
                      products={[service]}
                      itemType={itemType}
                      pricing={checkountInfo}
                      selectedCheckoutCart={selectedServiceCheckout}
                      promotionalCode={promotionalCode}
                    />
                  )}
                  <div className="checkout-button-mobile">
                    <CustomButton
                      title={"Continue to payment"}
                      processing={isPaymentLoading}
                      customStyle={{
                        marginLeft: "10%",
                        width: "80%",
                        fontWeight: "bold",
                      }}
                      handleButtonClick={handlePaymentClick}
                    />
                  </div>
                </Col>
                {/* Empty Column */}
                <Col
                  xs={1}
                  sm={1}
                  md={1}
                  style={{ backgroundColor: "transparent" }}
                ></Col>
              </Row>
            </Col>
          </Row>
          {showPaymentModal && (
            <Elements stripe={stripePromise} options={stripeOption}>
              {/* <PaymentElement/> */}
              <PaymentModal
                show={showPaymentModal}
                itemType={itemType}
                promotionalCode={promotionalCode}
                businessId={
                  itemType === "product"
                    ? selectedCheckoutCart.businessId
                    : selectedServiceCheckout.businessId
                }
                onHide={() => {
                  setShowPaymentModal(false);
                }}
              />
            </Elements>
          )}
        </>
      )}
    </Container>
  );
};

export default CheckoutPage;
