import { Route, Routes } from "react-router-dom";
import Membership from "../../components/membership/membership.component";

const MembershipNavigation = () => {
  return (
    <Routes>
      <Route index element={<Membership />} />
    </Routes>
  );
};

export default MembershipNavigation;
