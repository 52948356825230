import { Rating } from "react-simple-star-rating";
import { netural400, neturalBlack } from "../../utils/colors";
import { BsDot } from "react-icons/bs";

const ReviewList = (props) => {
  return (
    <div
      style={{
        marginTop: "3%",
      }}
    >
      {props.reviews?.map((reviewList, index) => {
        return (
          <div
            key={index}
            style={index == props.reviews.length - 1 ?
              {
              paddingTop: "3%",
              paddingBottom: "3%"
            }
              :{
              paddingTop: "3%",
              paddingBottom: "3%",
              borderBottom: "1px solid #dadada",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Rating
                allowFraction
                size={20}
                initialValue={reviewList.review_rating}
                readonly
                fillColor={neturalBlack}
              />
              <label style={{ fontSize: "16px", color: netural400 }}>
                {reviewList.review_date}
              </label>
            </div>
            <div style={{ marginTop: "3%" }}>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: neturalBlack,
                  width: "100%",
                }}
              >
                {reviewList.review_title}
              </label>
              <label style={{ fontSize: "16px", color: netural400 }}>
                {reviewList.review_description}
              </label>
            </div>
            <div
              style={{ marginTop: "3%", display: "flex", alignItems: "center" }}
            >
              <label
                style={{
                  fontSize: "14px",
                  color: neturalBlack,
                  fontWeight: "bold",
                  marginRight: "1%",
                }}
              >
                {reviewList.userName}
              </label>
              <BsDot style={{ marginRight: "1%" }} size={20} />
              <label style={{ fontSize: "14px", color: netural400 }}>
                Verified buyer
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ReviewList;
