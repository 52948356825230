import CustomLabel from "../shared/label/custom-label.component";

const PageTitle = (props) => {
  const { title } = props;
  return (
    <CustomLabel
      style={{
        fontSize: "32px",
        fontWeight: "600",
      }}
    >
      {title}
    </CustomLabel>
  );
};

export default PageTitle;
