import { FloatingLabel, Form } from "react-bootstrap";
import { netural400, neturalBlack } from "../../utils/colors";
import CustomButton from "../button/button.component";
import CustomLabel from "../shared/label/custom-label.component";
import { useEffect, useState } from "react";
import { selectCurrentUser } from "../../store/user/user.selector";
import { useSelector } from "react-redux";

const PersonalDetialForm = (props) => {
  const {
    handleContinueClick,
    section2Enabled,
    selectedAddress,
    editAddress,
    addNewAddress,
  } = props;
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const currentUser = useSelector(selectCurrentUser);
  const [isPersonalInfoValid, setPersonalInfoValid] = useState(false);
  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    lastName: "",
    email: currentUser.email? currentUser.email:"",
  });
  useEffect(() => {
    //  console.log("Addres------------", addNewAddress, editAddress,selectedAddress, currentUser);
    if (selectedAddress && Object.keys(selectedAddress).length !== 0) {
      setPersonalInfo({...personalInfo,firstName:selectedAddress.firstName, lastName:selectedAddress.lastName});
    } else {
      setPersonalInfo({
        firstName: "",
        lastName: "",
        email: currentUser.email? currentUser.email:"",
      });
    }
  }, [selectedAddress]);
  useEffect(() => {
    const email = personalInfo.email;
    // console.log("Email--------------", email,regexEmail.test(email),personalInfo)
    const isValid =
      personalInfo.firstName &&
      personalInfo.lastName
        ? addNewAddress ? regexEmail.test(email)
        : true : false; // Otherwise, return true (no validation)

    setPersonalInfoValid(isValid);
  }, [personalInfo, addNewAddress, editAddress]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        opacity: section2Enabled ? 1 : 0.5, // Adjust the opacity based on section2Enabled state
      }}
    >
      <CustomLabel
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          color: neturalBlack,
          width: "100%",
        }}
      >
        Personal details
      </CustomLabel>
      <CustomLabel
        style={{
          fontSize: "16px",
          fontWeight: "500",
          color: netural400,
        }}
      >
        Please enter your personal details below to keep you informed about your
        orders.
      </CustomLabel>
      <div style={{}}>
        <FloatingLabel controlId="floatingInput" label="First name">
          <Form.Control
            type="name"
            className="pt-3 mt-4"
            style={{ borderRadius: "12px", paddingTop: "4%" }}
            placeholder="First name"
            onChange={(e) => {
              let firstName = e.target.value;
              setPersonalInfo({ ...personalInfo, firstName });
            }}
            value={personalInfo.firstName}
            disabled={addNewAddress ? false : editAddress ? false : true} // Disable input when section 2 is disabled
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="Last name">
          <Form.Control
            type="name"
            className="pt-3 mt-4"
            style={{ borderRadius: "12px", paddingTop: "4%" }}
            placeholder="Last name"
            onChange={(e) => {
              let lastName = e.target.value;
              setPersonalInfo({ ...personalInfo, lastName });
            }}
            value={personalInfo.lastName}
            disabled={addNewAddress ? false : editAddress ? false : true} // Disable input when section 2 is disabled
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="Email">
          <Form.Control
            type="email"
            className="pt-3 mt-4"
            style={{ borderRadius: "12px", paddingTop: "4%" }}
            placeholder="Email"
            onChange={(e) => {
              let email = e.target.value;
              //   console.log("emial------------", email, regexEmail.test(email));
              setPersonalInfo({ ...personalInfo, email });
            }}
            value={personalInfo.email}
            disabled={addNewAddress ? false : editAddress ? true : true} // Disable input when section 2 is disabled
          />
        </FloatingLabel>
      </div>
      {section2Enabled && (
        <div style={{ marginTop: "5%" }}>
          <CustomButton
            title={"Continue"}
            //   processing={isLoading}
            buttonType={"validation"}
            isValid={isPersonalInfoValid}
            handleButtonClick={() => {
              handleContinueClick(isPersonalInfoValid, personalInfo);
            }}
            disabled={addNewAddress ? false : editAddress ? false : true} // Disable button when section 2 is disabled
          />
        </div>
      )}
    </div>
  );
};

export default PersonalDetialForm;
