import { Modal } from "react-bootstrap";
import {  netural400, neturalBlack } from "../../utils/colors";
import CustomButton from "../button/button.component";
import { useDispatch } from "react-redux";
import { signOutUser } from "../../utils/firebase/firebase.utils";
import { signOut, signOutFailure } from "../../store/user/user.action";
import { toast } from "react-toastify";
import { useState } from "react";
import CustomLabel from "../shared/label/custom-label.component";
import { resetUserCartCount } from "../../store/cart/cart.action";

const Logout = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleLogout = async()=> {
    setIsLoading(true)
    try{
        await signOutUser();
        dispatch(signOut());
        dispatch(resetUserCartCount());
        setIsLoading(false)
        props.onHide();
    }catch(error){
        setIsLoading(false)
        dispatch(signOutFailure())
        toast.error(error.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
    }
  }

  return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className="login-modal-style"
      >
        <div style={{ padding: "3%" }}>
          <Modal.Header
            closeButton
            style={{
              borderBottom: "0px solid rgb(0, 0, 0, 0.1)",
              paddingLeft: "3%",
              paddingRight: "3%",
              backgroundColor: "white",
              borderRadius: "12px",
              paddingBottom: "0px",
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
            <CustomLabel
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: neturalBlack,
                  }}
            >
                Logout
            </CustomLabel>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              marginBottom: "2%",
              paddingLeft: "3%",
              paddingRight: "3%",
              paddingTop: "1%",
            }}
          >
            <div style={{ marginBottom: "3%" }}>
              <CustomLabel
               style={{color:netural400}}
            >
                Are you sure, you wan to logout?
              </CustomLabel>
            </div>
            <div
              style={{
                marginTop: "8%",
                marginBottom: "4%",
              }}
            >
              <CustomButton
                customStyle={{fontWeight:"700"}}
                title={"Confirm"}
                processing={isLoading}
                handleButtonClick={handleLogout}
              />
            </div>
          </Modal.Body>
        </div>
      </Modal>
  );
};

export default Logout;
