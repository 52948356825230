import { Link } from "react-router-dom";
import CustomLabel from "../shared/label/custom-label.component";
import { netural400 } from "../../utils/colors";

const BreadcrumbsItemList = (props) => {
  const { resetFilterSort, selectedCategory, type } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "1%",
      }}
    >
      <Link to={"/"}>
        <CustomLabel
          style={{
            fontSize: "14px",
            color: netural400,
          }}
        >
          Home
        </CustomLabel>{" "}
      </Link>
      <label style={{ marginLeft: "1%", marginRight: "1%" }}>/</label>
      <Link
        to={
          type === "Products"
            ? "/products/category/Pets and Animals"
            : "/services"
        }
        onClick={resetFilterSort}
      >
        <CustomLabel
          style={{
            fontSize: "14px",
            color: netural400,
          }}
        >
          {type === "Products" ? "Products" : "Services"}
        </CustomLabel>{" "}
      </Link>
      {selectedCategory ? (
        <>
          <label style={{ marginLeft: "1%", marginRight: "1%" }}>/</label>
          <Link>
            <CustomLabel
              style={{
                fontSize: "14px",
                color: netural400,
              }}
            >
              {selectedCategory}
            </CustomLabel>{" "}
          </Link>
        </>
      ) : null}
    </div>
  );
};

export default BreadcrumbsItemList;
