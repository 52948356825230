import { ToastContainer } from "react-toastify";
import "./App.css";
import RoutePage from "./routes/RoutePage";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-toastify/dist/ReactToastify.css";
import 'react-calendar/dist/Calendar.css';

function App() {
  return (
    <div style={{ maxWidth: "1920px", margin: "0 auto" }}>
      <RoutePage />
      <ToastContainer />
    </div>
  );
}

export default App;
