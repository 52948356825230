import { useEffect, useState } from "react";
import { neturalBlack } from "../../utils/colors";
import CustomButton from "../button/button.component";
import CartItemList from "../cart-item-list/cart-item-list.component";

const CartList = ({
  cartItemInfo,
  index,
  isCheckoutLoading,
  handleUpdateQuantity,
  handleDeleteCartItem,
  handleCheckout,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  // let updatedQuantity = {};
  const getCheckOutTotal = (products) => {
    return products.reduce((total, product) => {
      return (
        total + parseFloat(product.finalTotal) * parseInt(product.quantity)
      );
    }, 0);
  };

  const checkOutTotal = getCheckOutTotal(cartItemInfo.items);

  return (
    <div
      key={index}
      style={{
        marginTop: "5%",
        border: "1px solid #dadada",
        borderRadius: "16px",
        padding: "3%",
      }}
    >
      <div>
        <img
          src={`${process.env.REACT_APP_HOST_URL}${cartItemInfo.businessLogo}`}
          alt="Profile"
          style={{ width: "7%", borderRadius: "50%" }}
        />
        <label
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: neturalBlack,
            marginLeft: "2%",
          }}
        >
          {cartItemInfo.companyName}
        </label>
      </div>
      {cartItemInfo.items.map((cartProductItem, index) => {
        return (
          <CartItemList
            key={index}
            index={index}
            cartProductItem={cartProductItem}
            handleUpdateQuantity={handleUpdateQuantity}
            handleDeleteCartItem={handleDeleteCartItem}
          />
        );
      })}
      <div
        style={{
          // marginTop: "5%",
          marginBottom: "5%",
        }}
      >
        <CustomButton
          title={
            "Continue to checkout $" + parseFloat(checkOutTotal).toFixed(2)
          }
          processing={isLoading}
          handleButtonClick={async() => {
            setIsLoading(true);
            await handleCheckout(cartItemInfo);
            setIsLoading(false)
          }}
        />
      </div>
    </div>
  );
};

export default CartList;
