import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Pagination from "../shared/pagination/pagination.component";
import Footer from "../home-page-footer/home-page-footer.component";
import { Col, Row } from "react-bootstrap";
import ProductList from "../product-list-design/product-list-design.component";
import FilterItemNotFound from "../filter-item-not-found/filter-item-not-found.component";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import FilterSortTabletMobile from "../filter-sort-tablet-mobile/filter-sort-tablet-mobile.component";
import FilterSortColumn from "../filter-sort-column/filter-sort-column.component";
import SearchTitleWithCount from "../search-title-with-item-count/search-title-with-item-count.component";
import PageTitle from "../page-title/page-title.component";
import BreadcrumbsItemList from "../breadcrumbs/breadcrumbs-item-list.component";
import SeacrhItemNotFound from "../search-item-not-found/search-item-not-found.component";
import { FETCH_SERVICES_WITH_FILTER_SORT } from "../../graphQL/services/services";
import { Country, State } from "country-state-city";

const ServiceList = () => {
  window.scrollTo(0, 0);
  const params = useParams();
  const { searchedKeyword, categoryName } = params;
  const [isFliterDeals, setIsFilterDeals] = useState({});
  const [isSortValue, setIsSortValue] = useState("Featured");
  const [searchedText, setSearchedText] = useState("");
  const [isFliterPrice, setIsFilterPrice] = useState({});
  const [filteredData, setFilteredData] = useState({
    minPrice: "",
    maxPrice: "",
  });
  const [serviceLocation, setServiceLocation] = useState({
    countryName: "",
    stateName: "",
    cityName: "",
  });
  const [serviceLocationArray, setServiceLocationArray] = useState({
    countryName: [],
    stateName: [],
    cityName: [],
  });
  const [filterServiceType, setFilterServiceType] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 24;

  useEffect(() => {
    // console.log("Params-------------------",categoryName)
    if (categoryName) {
      setSelectedCategory(categoryName);
    }
  }, [params]);

  useEffect(() => {
    if (searchedKeyword) {
      setSearchedText(searchedKeyword);
      // setSelectedCategory("Pets and Animals");
      setSelectedCategory("");
      setSelectedSubcategories([]);
      setServiceLocation({
        countryName: "",
        stateName: "",
        cityName: "",
      });
      setServiceLocationArray({
        countryName: [],
        stateName: [],
        cityName: [],
      });
    } else {
      setSearchedText("");
      setIsFilterDeals({});
      setIsSortValue("");
      setIsFilterPrice({});
      setFilteredData({ minPrice: "", maxPrice: "" });
      setDefaultLocation();
    }
  }, [params]);

  const setDefaultLocation = () => {
    const defaultCountry = Country.getCountryByCode("US");
    const defaultState = State.getStateByCodeAndCountry("FL", "US");
    setServiceLocation({
      countryName: defaultCountry.name,
      stateName: defaultState.name,
      cityName: "",
    });
    setServiceLocationArray({
      country: [defaultCountry],
      state: [defaultState],
      city: [],
    });
  };

  const handleOnChangeFilterPrice = (id) => {
    setPageNumber(1);
    const newCheckedItems = { ...isFliterPrice };
    if (newCheckedItems[id]) {
      delete newCheckedItems[id];
    } else {
      newCheckedItems[id] = true;
    }

    setIsFilterPrice(newCheckedItems);
    if (Object.keys(newCheckedItems).length !== 0) {
      const { minValue, maxValue } = calculateMinMax(newCheckedItems);

      setFilteredData((previousData) => ({
        ...previousData,
        minPrice: minValue.toString(),
        maxPrice: maxValue.toString(),
      }));
    } else {
      setIsFilterPrice({});
      setFilteredData((previousData) => ({
        ...previousData,
        minPrice: "",
        maxPrice: "",
      }));
    }
  };

  const calculateMinMax = (selectedValue) => {
    const numericalRanges = Object.keys(selectedValue).map((key) => {
      const [min, max] = key.split("-").map(Number);
      return { min, max };
    });

    // Find the minimum and maximum values from the numerical ranges
    let minValue = numericalRanges[0].min || 0;
    let maxValue = numericalRanges[0].max;
    numericalRanges.forEach((range) => {
      if (range.min < minValue) {
        minValue = range.min;
      }
      if (range.max > maxValue || isNaN(range.max)) {
        maxValue = range.max;
      }
    });
    // let minValue = 0;
    // let maxValue = 0;
    return { minValue, maxValue };
  };

  const handleOnChangeFilterDeals = (id) => {
    setPageNumber(1);
    const newCheckedItems = { ...isFliterDeals };
    if (newCheckedItems[id]) {
      delete newCheckedItems[id];
    } else {
      newCheckedItems[id] = true;
    }
    setIsFilterDeals(newCheckedItems);
    if (Object.keys(newCheckedItems).length !== 0) {
      setFilteredData((previousData) => ({
        ...previousData,
        discount: "discount",
      }));
    } else {
      setIsFilterDeals({});
      setFilteredData((previousData) => ({
        ...previousData,
        discount: "",
      }));
    }
  };

  const handleOnChnageFilterServiceType = (label, checked) => {
    setFilteredData((prevState) => {
      const key = label === "Online" ? "serviceOnline" : "serviceInPerson";
      if (checked) {
        const updatedState = {
          ...prevState,
          [key]: checked,
        };
        return updatedState;
      } else {
        const updatedState = { ...prevState };
        delete updatedState[key];
        return updatedState;
      }
    });
    setFilterServiceType((prevState) => {
      const key = label === "Online" ? "online" : "inPerson";
      const updatedState = { ...prevState };
      if (checked) {
        updatedState[key] = true;
      } else {
        delete updatedState[key];
      }
      return updatedState;
    });
  };

  const { loading, error, data } = useQuery(FETCH_SERVICES_WITH_FILTER_SORT, {
    variables: {
      filterBy: filteredData,
      location: serviceLocation,
      sortBy: isSortValue,
      searchedKeyword: searchedText,
      pageNumber: pageNumber,
      pageSize: pageSize,
      // category: selectedCategory,
      // subCategories: selectedSubcategories,
    },
  });

  if (error) return <p>Error: {error.message}</p>;
  const totalCount = Math.ceil(
    data?.fetchServicesWithFilterAndSort?.totalCount / pageSize
  );

  const handleCheckChange = (e) => {
    setPageNumber(1);
    setIsSortValue(e.target.value);
  };

  const handleLocationChange = (allCountry, selectedState, selectedCity) => {
    setServiceLocationArray({
      country: allCountry,
      state: selectedState,
      city: selectedCity,
    });
    setServiceLocation({
      countryName: allCountry.length > 0 ? allCountry[0].name : "",
      stateName: selectedState.length > 0 ? selectedState[0].name : "",
      cityName: selectedCity.length > 0 ? selectedCity[0].name : "",
    });
  };

  const resetFilterSort = () => {
    setIsFilterPrice({});
    setIsFilterDeals({});
    setFilteredData({ minPrice: "", maxPrice: "" });
    setIsSortValue("");
    setSearchedText("");
    setPageNumber(1);
    setSelectedCategory("");
    setSelectedSubcategories([]);
    setFilterServiceType({});
  };

  const handleButtonClick = (
    selectedFiltered,
    selectedSort,
    selectedSubcategories,
    mobileFilterServiceType,
    isMobileFliterPrice,
    isMobileFliterDeals
  ) => {
    setPageNumber(1);
    setFilteredData(selectedFiltered);
    setIsFilterPrice(isMobileFliterPrice);
    setIsFilterDeals(isMobileFliterDeals);
    setIsSortValue(
      selectedSort === "Featured"
        ? ""
        : selectedSort === "Price (low-high)"
        ? "price"
        : selectedSort === "Price (high-low)"
        ? "-price"
        : ""
    );
    setSelectedSubcategories(selectedSubcategories);
    setFilterServiceType(mobileFilterServiceType);
  };

  return (
    <>
      {data?.fetchServicesWithFilterAndSort?.services.length === 0 &&
      searchedText &&
      !filteredData.minPrice ? (
        <SeacrhItemNotFound searchedText={searchedText} type={"Services"} />
      ) : (
        <div style={{ width: "99%", marginTop: "2%" }}>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col
              xl={11}
              lg={11}
              md={11}
              sm={11}
              xs={11}
              className="scrollable-product-column"
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <BreadcrumbsItemList
                  resetFilterSort={resetFilterSort}
                  selectedCategory={selectedCategory}
                  type={"Services"}
                />
                <PageTitle title={"Services"} />
              </div>
              <SearchTitleWithCount
                searchedText={searchedText}
                itemCount={data?.fetchServicesWithFilterAndSort?.totalCount}
              />
            </Col>
            <FilterSortColumn
              isFliterPrice={isFliterPrice}
              isFliterDeals={isFliterDeals}
              setIsFilterDeals={setIsFilterDeals}
              setIsFilterPrice={setIsFilterPrice}
              setFilteredData={setFilteredData}
              handleOnChangeFilterPrice={handleOnChangeFilterPrice}
              handleOnChangeFilterDeals={handleOnChangeFilterDeals}
              isSortValue={isSortValue}
              handleCheckChange={handleCheckChange}
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
              setSelectedSubcategories={setSelectedSubcategories}
              selectedSubcategories={selectedSubcategories}
              setPageNumber={setPageNumber}
              searchedText={searchedText}
              type={"Services"}
              resetFilterSort={resetFilterSort}
              filterServiceType={filterServiceType}
              setFilterServiceType={setFilterServiceType}
              handleOnChnageFilterServiceType={handleOnChnageFilterServiceType}
              handleLocationChange={handleLocationChange}
              serviceLocationArray={serviceLocationArray}
            />
            {/* <Col lg={12} md={12} sm={12} xs={12}> */}
            {/* </Col> */}
            <FilterSortTabletMobile
              categoryName={categoryName}
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
              setSelectedSubcategories={setSelectedSubcategories}
              selectedSubcategories={selectedSubcategories}
              searchedText={searchedText}
              handleButtonClick={handleButtonClick}
              type={"Services"}
              isSortValue={isSortValue}
              handleCheckChange={handleCheckChange}
              isFliterPrice={isFliterPrice}
              isFliterDeals={isFliterDeals}
              setIsFilterDeals={setIsFilterDeals}
              setIsFilterPrice={setIsFilterPrice}
              resetFilterSort={resetFilterSort}
              filterServiceType={filterServiceType}
              handleLocationChange={handleLocationChange}
              serviceLocationArray={serviceLocationArray}
            />
            <Col
              xl={10}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="scrollable-product-column"
            >
              <Row className="product-service-list-tablet-mobile">
                {loading ? (
                  <LoadingSpinner />
                ) : data?.fetchServicesWithFilterAndSort?.services.length ===
                  0 ? (
                  <FilterItemNotFound
                    resetFilterSort={resetFilterSort}
                    displayFilterMessage={
                      (!searchedText && filteredData.minPrice) ||
                      (searchedText && filteredData.minPrice)
                        ? true
                        : false
                    }
                  />
                ) : (
                  data?.fetchServicesWithFilterAndSort?.services.map(
                    (productList, index) => {
                      return (
                        <ProductList
                          key={index}
                          productList={productList}
                          index={index}
                          type={"Services"}
                        />
                      );
                    }
                  )
                )}
              </Row>
            </Col>
            <Col xl={2} lg={0} md={0} sm={12} xs={12}></Col>
            <Col
              xl={10}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ marginTop: "2%" }}
            >
              {!loading &&
              data?.fetchServicesWithFilterAndSort?.services.length !== 0 ? (
                <Pagination
                  pageNumber={pageNumber}
                  totalPages={totalCount}
                  siblings={1}
                  itemsPerPage={pageSize}
                  leftAndRightSkip={3}
                  onPageChange={(page) => {
                    setPageNumber(page);
                  }}
                />
              ) : null}
            </Col>
          </Row>
        </div>
      )}
      <Footer />
    </>
  );
};

export default ServiceList;
