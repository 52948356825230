import { Modal } from "react-bootstrap";
import OTPInput from "react-otp-input";
import CustomButton from "../button/button.component";
import { netural400, neturalBlack } from "../../utils/colors";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { CREATE_USER, LOGIN } from "../../graphQL/auth/auth.query";
import { useDispatch } from "react-redux";
import { signInFailure, signInSuccess } from "../../store/user/user.action";

const VerifyOTPModal = (props) => {
  const {formattedNumber, confirmationResult, actionType} = props;
  const [otp, setOtp] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [createUser] = useMutation(CREATE_USER);
  const [login, { loading, error, data }] = useMutation(LOGIN, {
    fetchPolicy: "no-cache",
  });

  useEffect(()=>{
    if(otp && otp.length === 6){
      setIsOtpValid(true)
    }else{
      setIsOtpValid(false)
    }
  },[otp])

  const handleLogin = async () => {
    await login({
      variables: {
        phone: formattedNumber,
        type: "login",
      },
    });
  };

  const handleSignup = async () => {
    const{userInfo} =props;
    const {data:{signUp}} = await createUser({
      variables: {
        phone: formattedNumber,
        name:userInfo.name,
        country:userInfo.country,
        notificationTokenLocal:null,
        referrerId:null,
        email:userInfo.email,
      },
    });
    // console.log("SIGNUP ", data)
    if(signUp._id != ""){
      handleLogin();
    }else{
      setIsLoading(false);
      toast.error("Something went wrong, Please try again later.", {
        style: { width: "350px" },
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (!loading && data) {
      if (data.login) {
        const loggedIn = data.login;
        dispatch(signInSuccess(loggedIn));
        setIsLoading(false)
        props.onHide();
      }
      if(error && !loading){
        setIsLoading(false);
        dispatch(signInFailure(error.message))
        toast.error(error.message, {
          style: { width: "350px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }, [loading, error, data]);

  const handleVerifyClick = () => {
    setIsLoading(true);
    if (isOtpValid) {
      confirmationResult
        .confirm(otp)
        .then((result) => {
          // User signed in successfully.
          const user = result.user;
          if(actionType === "login"){
            handleLogin();
          }else{
            handleSignup();
          }
        })
        .catch((error) => {
          setIsLoading(false);
          // User couldn't sign in (bad verification code?)
          toast.error("Invalid OTP Code,Please try again", {
            style: { width: "350px" },
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="login-modal-style"
    >
      <div style={{ padding: "3%" }}>
        <Modal.Header
          closeButton
          style={{
            borderBottom: "0px solid rgb(0, 0, 0, 0.1)",
            paddingLeft: "3%",
            paddingRight: "3%",
            backgroundColor: "white",
            borderRadius: "12px",
            paddingBottom: "0px",
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            <label>Verify phone number</label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            marginBottom: "2%",
            paddingLeft: "3%",
            paddingRight: "3%",
            paddingTop: "1%",
          }}
        >
          <div
            style={{
              marginBottom: "3%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label
              style={{ fontSize: "16px", fontWeight: "500", color: netural400 }}
            >
              Please enter the verification code sent to
            </label>
            <label
              style={{ fontSize: "16px", fontWeight: "bold", color: neturalBlack }}
            >
              {formattedNumber}
            </label>
          </div>
          <div style={{ marginTop: "5%", width: "100%" }}>
            <OTPInput
              value={otp}
              onChange={(value)=>setOtp(value)}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              //   inputType="number"
              inputStyle={{
                width: "12%",
                height: "7vh",
                margin: "2%",
                fontSize: "1.6rem",
                borderRadius: "8px",
                border: "1px solid #dadada",
              }}
              containerStyle={{ justifyContent: "center" }}
            />
          </div>
          <div
            style={{
              marginTop: "8%",
              marginBottom: "8%",
            }}
          >
            <CustomButton
              title={"Continue"}
              processing={isLoading}
              buttonType={"validation"}
              isValid={isOtpValid}
              handleButtonClick={handleVerifyClick}
            />
          </div>
          {/* <div style={{ borderTop: "1px solid #dadada" }}>
            <div
              style={{
                marginTop: "8%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: neturalBlack,
                }}
              >
                Already have an account?
              </label>
            </div>
            <div style={{ marginTop: "3%" }}>
              <CustomButton
                title={"Sign in"}
                buttonType={"inverted"}
                // isValid={isPhoneValid}
                // handleButtonClick={handleSignInClick}
              />
            </div>
          </div> */}
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default VerifyOTPModal;
