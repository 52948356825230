import { USER_ACTION_TYPES } from "./user.types";

export const signInSuccess = (currentUser) => ({
    type: USER_ACTION_TYPES.SIGN_IN_SUCCESS,
    payload: { currentUser },
  });
export const updateCurrentUser = (currentUser) => ({
    type: USER_ACTION_TYPES.UPDATE_CURRENT_USER,
    payload: { currentUser },
  });
  
  export const signInFailure = (error) => ({
    type: USER_ACTION_TYPES.SIGN_IN_FAILED,
    payload: error,
  });

  export const signOut = () => ({
    type: USER_ACTION_TYPES.SIGN_OUT_SUCCESS,
  });
  
  export const signOutFailure = (error) => ({
    type: USER_ACTION_TYPES.SIGN_OUT_FAILED,
    payload: error,
  });
  
 

  