import { Form } from "react-bootstrap";
import Collapsible from "react-collapsible";
import { netural400, primaryOrange } from "../../utils/colors";
import CloseFilterTitle from "../filter-title-section/filter-title-close-section.component";
import OpenFilterTitle from "../filter-title-section/filter-title-open-section.component";
import CustomCheckbox from "../checkbox/checkbox.component";
import CustomLabel from "../shared/label/custom-label.component";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  FETCH_CATEGORIES_BASED_ON_PRODUCTS,
  FETCH_SUBCATEGORIES_BY_NAME,
} from "../../graphQL/products/products";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import "./filter-mobile-section.style.css";
import { useNavigate } from "react-router-dom";

const filterList = {
  "$0 - $50": "0 - 50",
  "$50 - $100": "50 - 100",
  "$100 - $200": "100 - 200",
  "$200 or more": "200 - more",
};
const FilterMobile = (props) => {
  const {
    isFliterPrice,
    isFliterDeals,
    handleOnChangeFilterPrice,
    handleOnChangeFilterDeals,
    handleOnChangeCategory,
    selectedCategory,
    setSelectedSubcategories,
    selectedSubcategories,
    type,
    filterServiceType,
    handleOnChnageFilterServiceType,
  } = props;
  const navigation = useNavigate();
  const [categoriesList, setCategoriesList] = useState([]);
  const [subcategoriesList, setSubcategoriesList] = useState([]);

  const [fetchCategoriesBasedOnProducts] = useLazyQuery(
    FETCH_CATEGORIES_BASED_ON_PRODUCTS,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.fetchCategoriesBasedOnProducts) {
          let listOfCategory = data.fetchCategoriesBasedOnProducts;
          const updatedSelected = listOfCategory.map((item) => ({
            ...item,
            isChecked: false,
          }));
          setCategoriesList(updatedSelected);
          // console.log("value", listOfCategory);
        } else {
        }
      },
    }
  );

  const [
    fetchSubCategoriesByCategoryName,
    { loading: loadingSubcategoryList },
  ] = useLazyQuery(FETCH_SUBCATEGORIES_BY_NAME, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data && data.fetchSubCategoriesByCategoryName) {
        let subCategoriesByName = data.fetchSubCategoriesByCategoryName;
        if (selectedSubcategories && selectedSubcategories.length === 0) {
          subCategoriesByName = subCategoriesByName.map((item) => ({
            ...item,
            isChecked: false,
          }));
          setSubcategoriesList(subCategoriesByName);
        } else {
          // Extract all _ids from dataToCheck array
          const idsToCheck = selectedSubcategories.map((item) => item._id);

          // Update isChecked based on whether _id is present in idsToCheck
          subCategoriesByName = subCategoriesByName.map((item) => ({
            ...item,
            isChecked: idsToCheck.includes(item._id) ? true : false,
          }));
          setSubcategoriesList(subCategoriesByName);
        }
      }
    },
    onError: (error) => {
      if (error.message === "Category does not exist") navigation("/products");
    },
  });

  useEffect(() => {
    if (type === "Products") {
      fetchCategoriesBasedOnProducts();
    }
  }, []);

  useEffect(() => {
    if (selectedCategory && type === "Products") {
      fetchSubCategoriesByCategoryName({
        variables: {
          categoryName: selectedCategory,
        },
      });
    }
  }, [selectedCategory]);

  return (
    <div>
      {type === "Products" && loadingSubcategoryList ? (
        <LoadingSpinner />
      ) : subcategoriesList.length > 0 ? (
        <>
          <Collapsible
            triggerStyle={{
              fontSize: "16px",
              fontWeight: "500",
              width: "100%",
              display: "flex",
            }}
            trigger={
              <CloseFilterTitle
                isFliter={selectedSubcategories}
                title={"Sub-category"}
              />
            }
            triggerWhenOpen={
              <OpenFilterTitle
                isFliter={selectedSubcategories}
                title={"Sub-category"}
              />
            }
          >
            <div className="mobile-category-section">
              {subcategoriesList.map((label, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Form.Check
                    inline
                    label={label.name}
                    name="group1"
                    type={"checkbox"}
                    id={index}
                    style={{ marginBottom: "3%" }}
                    checked={label.isChecked}
                    onChange={() => {
                      let selectedValueOfSubcategories = {
                        _id: label._id,
                        name: label.name,
                      };
                      const checkSubcategories = selectedSubcategories.some(
                        (item) => item._id === label._id
                      );
                      if (checkSubcategories) {
                        setSelectedSubcategories((prevSelected) =>
                          prevSelected.filter((item) => item._id !== label._id)
                        );

                        setSubcategoriesList((prevSelected) => {
                          const updatedSelected = [...prevSelected];
                          if (updatedSelected[index]) {
                            updatedSelected[index].isChecked = false; // Update isChecked to true for this example
                          }
                          return updatedSelected;
                        });
                      } else {
                        setSelectedSubcategories([
                          ...selectedSubcategories,
                          selectedValueOfSubcategories,
                        ]);

                        setSubcategoriesList((prevSelected) => {
                          const updatedSelected = [...prevSelected];
                          if (updatedSelected[index]) {
                            updatedSelected[index].isChecked = true; // Update isChecked to true for this example
                          }
                          return updatedSelected;
                        });
                      }
                      // handleOnChangeFilterDeals(label);
                    }}
                  />
                </div>
              ))}
            </div>
          </Collapsible>
          <div
            style={{
              marginTop: "5%",
              marginBottom: "5%",
              borderTop: "solid 1px #e0e0e0",
            }}
          ></div>
        </>
      ) : null}
      {type === "Products" &&
      (subcategoriesList.length === 0 ||
        selectedCategory === "Pets and Animals") ? (
        <>
          <Collapsible
            triggerStyle={{
              fontSize: "16px",
              fontWeight: "500",
              width: "100%",
              display: "flex",
            }}
            trigger={<CloseFilterTitle isFliter={{}} title={"Categories"} />}
            triggerWhenOpen={
              <OpenFilterTitle isFliter={{}} title={"Categories"} />
            }
          >
            <div className="mobile-category-section">
              {categoriesList &&
                categoriesList.map((label, index) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                        onClick={() => {
                          handleOnChangeCategory(label);
                        }}
                      >
                        <CustomLabel
                          style={{
                            color:
                              selectedCategory === label.name
                                ? netural400
                                : primaryOrange,
                            textDecoration: "underline",
                          }}
                        >
                          {" "}
                          {label.name}{" "}
                        </CustomLabel>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Collapsible>
          <div
            style={{
              marginTop: "5%",
              marginBottom: "5%",
              borderTop: "solid 1px #e0e0e0",
            }}
          ></div>
        </>
      ) : null}
      <Collapsible
        triggerStyle={{
          fontSize: "16px",
          fontWeight: "500",
          width: "100%",
          display: "flex",
        }}
        open
        trigger={<CloseFilterTitle isFliter={isFliterPrice} title={"Price"} />}
        triggerWhenOpen={
          <OpenFilterTitle isFliter={isFliterPrice} title={"Price"} />
        }
      >
        <CustomCheckbox
          checkboxList={filterList}
          isCheckboxChecked={isFliterPrice}
          handleOnChangeCheckbox={handleOnChangeFilterPrice}
        />
      </Collapsible>
      <div
        style={{
          marginTop: "5%",
          marginBottom: "5%",
          borderTop: "solid 1px #e0e0e0",
        }}
      ></div>
      <Collapsible
        triggerStyle={{
          fontSize: "16px",
          fontWeight: "500",
          width: "100%",
          display: "flex",
        }}
        open
        trigger={<CloseFilterTitle isFliter={isFliterDeals} title={"Deals"} />}
        triggerWhenOpen={
          <OpenFilterTitle isFliter={isFliterDeals} title={"Deals"} />
        }
      >
        <div
          style={{
            marginTop: "3%",
          }}
        >
          {["On sale"].map((label, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Form.Check
                inline
                label={label}
                name="group1"
                type={"checkbox"}
                id={index}
                style={{ marginBottom: "3%" }}
                checked={isFliterDeals[label] === true}
                onChange={() => handleOnChangeFilterDeals(label)}
              />
            </div>
          ))}
        </div>
      </Collapsible>
      <div
        style={{
          marginTop: "5%",
          marginBottom: "5%",
          borderTop: "solid 1px #e0e0e0",
        }}
      ></div>
      {type === "Services" && (
        <>
          <Collapsible
            triggerStyle={{
              fontSize: "16px",
              fontWeight: "500",
              width: "100%",
              display: "flex",
            }}
            open
            trigger={
              <CloseFilterTitle
                isFliter={filterServiceType}
                title={"Service type"}
              />
            }
            triggerWhenOpen={
              <OpenFilterTitle
                isFliter={filterServiceType}
                title={"Service type"}
              />
            }
          >
            <div
              style={{
                marginTop: "3%",
              }}
            >
              {["Online", "In person"].map((label, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Form.Check
                    inline
                    label={label}
                    name="group1"
                    type={"checkbox"}
                    id={index}
                    style={{ marginBottom: "3%" }}
                    checked={
                      label === "Online"
                        ? !!filterServiceType?.online
                        : !!filterServiceType?.inPerson
                    }
                    onChange={(e) =>
                      handleOnChnageFilterServiceType(label, e.target.checked)
                    }
                  />
                </div>
              ))}
            </div>
          </Collapsible>
          <div
            style={{
              marginTop: "15%",
              marginBottom: "15%",
              borderTop: "solid 1px #e0e0e0",
            }}
          ></div>
        </>
      )}
    </div>
  );
};

export default FilterMobile;
