import { Form, Modal } from "react-bootstrap";
import { netural400, neturalBlack } from "../../utils/colors";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useState } from "react";
import CustomButton from "../button/button.component";
import LoginModal from "../login/login.component";
import CustomLabel from "../shared/label/custom-label.component";
import { SIGN_UP_CHECK } from "../../graphQL/auth/auth.query";
import { useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import {
  generateRecaptcha,
  signInWithUserPhoneNumber,
} from "../../utils/firebase/firebase.utils";
import PhoneNumber from 'awesome-phonenumber';

const SignupModal = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneValid, setPhoneValid] = useState(false);
  const [formattedNumber, setFormattedNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [checkUser] = useLazyQuery(SIGN_UP_CHECK, {
    fetchPolicy: "no-cache",
  });

  const onSignUp = async (appVerifier) => {
    // console.log("onSignInmethod", appVerifier);
    try {
      const confirmation = await signInWithUserPhoneNumber(
        phoneNumber,
        appVerifier
      );
      setIsLoading(false);
      props.onHide();
      const userInfo = {
        name,
        country:countryCode,
        email,
      }
      props.handleSignUpClick(formattedNumber, confirmation, "signup", userInfo)
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const verifyInDatabase = async () => {
    try {
      const {
        data: { signUpCheck },
      } = await checkUser({
        variables: {
          phone: phoneNumber,
          name,
          ofAge: true,
          agreeToTerms: true,
        },
      });
      if (signUpCheck.completed) {
        const recaptcha = generateRecaptcha();
        if (recaptcha) {
          onSignUp(recaptcha);
        }
      //  console.log(signUpCheck, "SignUpCheck DATA");
      } else {
        setIsLoading(false);
        toast.error(signUpCheck.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      setIsLoading(false);
    //  console.log(error);
      toast.error(error.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const handleSignUpClick = async () => {
    if (isPhoneValid && name.length >= 3) {
      setIsLoading(true);
      verifyInDatabase();
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className="login-modal-style"
      >
        <div style={{ padding: "3%" }}>
          <Modal.Header
            closeButton
            style={{
              borderBottom: "0px solid rgb(0, 0, 0, 0.1)",
              paddingLeft: "3%",
              paddingRight: "3%",
              backgroundColor: "white",
              borderRadius: "12px",
              paddingBottom: "0px",
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <CustomLabel
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: neturalBlack,
                }}
              >
                Sign up
              </CustomLabel>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              marginBottom: "2%",
              paddingLeft: "3%",
              paddingRight: "3%",
              paddingTop: "1%",
            }}
          >
            <div style={{ marginBottom: "3%" }}>
              <CustomLabel
                style={{
                  fontWeight: "400",
                  color: netural400,
                }}
              >
                Sign in to website with the phone number registered.
              </CustomLabel>
            </div>
            <div style={{ marginTop: "5%" }}>
              <Form.Group style={{ color: "#A6A6A6" }}>
                <Form.Label style={{ color: netural400 }}>Name</Form.Label>
                <Form.Control
                  required
                  style={{ borderRadius: "12px" }}
                  type="text"
                  value={name}
                  minLength={3}
                  maxLength={30}
                  onChange={(value) => {
                    if (value.target.value.trim().length !== 0) {
                      setName(value.target.value);
                    } else {
                      setName("");
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid name.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div style={{ marginTop: "3%" }}>
              <Form.Label style={{ color: netural400 }}>
                Phone number
              </Form.Label>
              <PhoneInput
                country={"ca"}
                value={phoneNumber}
                enableSearch
                onChange={(value) => {
                  if (value && isValidPhoneNumber(value)) {
                    const phoneNumberObj = new PhoneNumber(value);
                 //   console.log(phoneNumberObj.getNumber('international'))
                    setCountryCode(phoneNumberObj.getRegionCode())
                    setPhoneNumber(value);
                    setFormattedNumber(value);
                    setPhoneValid(true);
                  } else {
                    setPhoneValid(false);
                  }
                }}
                international
                defaultCountry="CA"
              />
            </div>
            <div style={{ marginTop: "3%" }}>
              <Form.Group style={{ color: "#A6A6A6" }}>
                <Form.Label style={{ color: netural400 }}>
                  Email (optional)
                </Form.Label>
                <Form.Control
                  required
                  style={{ borderRadius: "12px" }}
                  type="text"
                  //   placeholder="Name"
                  value={email}
                  onChange={(value) => {
                    if (value.target.value.trim().length !== 0) {
                      setEmail(value.target.value);
                    } else {
                      setEmail("");
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid name.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div
              style={{
                marginTop: "8%",
                marginBottom: "4%",
              }}
            >
              <CustomButton
                title={"Continue"}
                buttonType={"validation"}
                processing={isLoading}
                isValid={isPhoneValid && name.length >= 3}
                handleButtonClick={handleSignUpClick}
              />
            </div>
            <div style={{ marginTop: "4%", marginBottom: "4%" }}>
              <CustomLabel
                style={{
                  fontSize: "12px",
                  color: netural400,
                  textAlign: "center",
                  marginLeft: "10%",
                  marginRight: "10%",
                }}
              >
                By continuing, you agree to{" "}
                <a
                  href="https://www.my-greggii.com/store-policy"
                  target="_blank"
                  style={{ color: netural400, fontWeight: "bold" }}
                >
                  {" "}
                  Terms & Conditions{" "}
                </a>{" "}
                and confirm you are at least 13 years of age.
              </CustomLabel>
            </div>
            <div style={{ borderTop: "1px solid #dadada" }}>
              <div
                style={{
                  marginTop: "8%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CustomLabel
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                  }}
                >
                  Already have an account?
                </CustomLabel>
              </div>
              <div style={{ marginTop: "3%" }}>
                <CustomButton
                  title={"Sign in"}
                  buttonType={"inverted"}
                  handleButtonClick={props.handleSignInClick}
                />
              </div>
            </div>
          </Modal.Body>
        </div>
        <div id="recaptcha-container"></div>
      </Modal>
      {showLoginModal && (
        <LoginModal
          show={showLoginModal}
          onHide={() => {
            setShowLoginModal(false);
          }}
        />
      )}
    </>
  );
};

export default SignupModal;
