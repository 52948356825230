import React from 'react';
import {Navigate } from 'react-router-dom';
import { isUserLoggedIn } from '../store/user/user.selector';
import { useSelector } from "react-redux";

const AuthRoute = ({ element: Element, ...props }) => {
  const isUserAuthenticated = useSelector(isUserLoggedIn);
  
  return isUserAuthenticated ? (
    <Element {...props} />
  ) : (
    <Navigate to="/" replace />
  );
};

export default AuthRoute;
