import { neturalBlack, primaryOrange } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";

const AddressList = ({ label, index, setEditAddress, handleAddressChange }) => {
  return (
    <div
      key={index}
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <label
        htmlFor={`radio-${index}`}
        style={{
          marginBottom: "3.5%",
          display: "flex",
          alignItems: "center",
          width: "93%",
          fontSize: "16px",
          fontWeight: "500",
          color: neturalBlack,
        }}
      >
        <input
          id={`radio-${index}`}
          type="radio"
          name="group1"
          value={label.address}
          className="custom-radio"
          checked={label.activeAddress} // Check the radio button if lastSelected is true
          onChange={() => handleAddressChange(label.key, "")} // Update the selected address
        />
        {label.address}
      </label>
      <div
        style={{ width: "7%" }}
        onClick={() => {
          setEditAddress(true);
          handleAddressChange(label.key, "edit");
        }}
      >
        <CustomLabel
          style={{
            color: primaryOrange,
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          Edit
        </CustomLabel>
      </div>
    </div>
  );
};

export default AddressList;
