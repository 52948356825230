import { useState } from "react";
import "./increment-decrement-button.style.css";
import { Form, InputGroup } from "react-bootstrap";

const IncrementDecrementButton = ({
  minValue,
  maxValue,
  quantityCount,
  setQuantityCount,
}) => {
  const handleIncrementCounter = () => {
    if (quantityCount && quantityCount < maxValue) {
      setQuantityCount((prevState) => parseInt(prevState) + 1);
    } else {
      setQuantityCount(minValue);
    }
  };

  const handleDecrementCounter = () => {
    if (quantityCount > minValue) {
      setQuantityCount((prevState) => parseInt(prevState) - 1);
    }
  };

  return (
    <div className="btn-group">
      <button
        className={quantityCount > 1 ? "decrement-btn" : "disabled-btn"}
        onClick={() => {
          if (quantityCount !== 1) {
            handleDecrementCounter();
          }
        }}
      >
        <span className="material-symbols-outlined">remove</span>
      </button>
      <InputGroup
        style={{
          color: "#A6A6A6",
          border: "0px solid #dadada",
          display: "flex",
          alignItems: "center",
          padding: "0px",
        }}
      >
        <Form.Control
          required
          type="number"
          style={{ border: "none", paddingRight: "0px", textAlign: "center" }}
          value={quantityCount}
          onChange={(value) => {
            const inputValue = value.target.value;
            if (
              inputValue.match("^[1-9]") != null &&
              parseInt(inputValue) >= 1
            ) {
              if (parseInt(inputValue) > 50) {
                setQuantityCount(maxValue);
              } else {
                setQuantityCount(inputValue);
              }
            } else {
              setQuantityCount(minValue);
            }
          }}
        />
      </InputGroup>
      <button className="increment-btn" onClick={handleIncrementCounter}>
        <span className="material-symbols-outlined">add</span>
      </button>
    </div>
  );
};

export default IncrementDecrementButton;
