import React from "react";
import HomePageBrowseProductsServices from "../home-page-product-and-service/home-page-browse-products-and-services.component";
import ProductServiceViewAllHome from "../home-page-section-3-view-all/product-service-viewall-home.component";
import ImageCarousel from "../image-carousel/image-carousel.component";
import CompanyLogoSlider from "../home-page-logo-slider/home-page-company-logo.component";
import DownloadApp from "../home-page-download/home-page-download.component";
import Footer from "../home-page-footer/home-page-footer.component";
import { useQuery } from "@apollo/client";
import { FETCH_LANDING_PAGE_INFO } from "../../graphQL/landing-page/landing-page";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";

const HomePage = () => {

  const { loading, error, data } = useQuery(FETCH_LANDING_PAGE_INFO);
  if (loading) return <LoadingSpinner overlay/>;
  if (error) return <p>Error: {error.message}</p>;
  // if (data) {
  //   console.log("FETCH LANDING PAGE INFO", data.fetchingLandingPageInfo)
  // }

  return (
    <>
      <ImageCarousel imagesList={data.fetchingLandingPageInfo.carouselImages} />
      <HomePageBrowseProductsServices />
      <ProductServiceViewAllHome products={data.fetchingLandingPageInfo.products} title={"Products"} />
      <CompanyLogoSlider logo={data.fetchingLandingPageInfo.trustedPatners} />
      <DownloadApp />
      <Footer />
    </>
  );
};

export default HomePage;
