export const selectCurrentUser = (state) => state.user.currentUser; 
export const selectCurrentUserShippingAddress = (state) => {
    const user = state.user.currentUser
    if(user.shippingAddress){
        const shippingAddress={
            ...user.shippingAddress,
            email:user.email
        }
        return shippingAddress
    }else{
        return null;
    }
}; 
export const isUserLoggedIn = (state) => state.user.isUserLoggedIn;