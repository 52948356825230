import { Route, Routes } from "react-router-dom";
import ServiceList from "../../components/service-list/service-list.component";
import ServiceDetail from "../../components/service-detail/service-detail.component";

const Services = () => {
  return (
    <Routes>
      <Route index element={<ServiceList />} />
      <Route path="details/:serviceId" element={<ServiceDetail />} />
      <Route path="search/:searchedKeyword" element={<ServiceList />} />
    </Routes>
  );
};

export default Services;
