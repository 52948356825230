import { BsPlusLg } from "react-icons/bs";
import CustomLabel from "../shared/label/custom-label.component";

const CloseFilterTitle = (props) => {
  const { isFliter, title } = props;
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <CustomLabel style={{ width: "90%" }}>
        {title} {" "}
        {Object.keys(isFliter).length > 0
          ? "[" + Object.keys(isFliter).length + "]"
          : null}{" "}
      </CustomLabel>
      <BsPlusLg />
    </div>
  );
};

export default CloseFilterTitle;
