import { Carousel } from "react-responsive-carousel";

const ImageCarousel = ({ imagesList }) => {
  return (
    <Carousel
      emulateTouch
      autoPlay
      infiniteLoop
      transitionTime={500}
      animationHandler={"fade"}
      showThumbs={false}
      showArrows={false}
      dynamicHeight
      swipeable={false}
    >
      {imagesList.map((data, index) => {
        return (
          <div key={index}>
            <img alt="" src={`${process.env.REACT_APP_HOST_URL}${data.imageUrl}`} />
          </div>
        );
      })}
    </Carousel>
  );
};

export default ImageCarousel;
