import { Form } from "react-bootstrap";
import Collapsible from "react-collapsible";

const SortMobile = (props) => {
  const { isSortValue, handleCheckChange } = props;
  return (
    <div>
      <Collapsible open>
        <div style={{ marginTop: "3%", marginBottom: '10%' }}>
          {["Featured", "Price (low-high)", "Price (high-low)"].map(
            (label, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1.5%",
                }}
              >
                <Form.Check
                  inline
                  label={label}
                  name="sortOption"
                  type={"radio"}
                  id={`radio-${index}`}
                  checked={isSortValue === label}
                  onChange={() => handleCheckChange(label)}
                />
              </div>
            )
          )}
        </div>
      </Collapsible>
    </div>
  );
};

export default SortMobile;
