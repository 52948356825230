import { auth } from "../../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber, signOut } from "firebase/auth";

// Sign In with Phone Number, used for Sign In, Sign Up and Resend OTP 
export const signInWithUserPhoneNumber = async (phoneNumber, appVerifier) => {
    const confirmation = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
    return confirmation;
  }

export const generateRecaptcha = () => {
    const recaptcha = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {},
      },
      auth
    );
    return recaptcha
};

export const signOutUser = async () => await signOut(auth);