import React, { useRef, useEffect } from "react";
import { FloatingLabel, Form } from "react-bootstrap";

function AutocompleteInput({ onPlaceSelected, country }) {
  const inputRef = useRef(null);
  let autocomplete = null;

  useEffect(() => {
    // console.log("Country-----------------", country)
    if (inputRef.current) {
      autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"], // Limit autocomplete to geocode results
          componentRestrictions: {
            country: country.countryCode || "",
          },
        }
      );

      autocomplete.addListener("place_changed", handlePlaceSelect);
    }

    return () => {
      if (autocomplete) {
        autocomplete.unbindAll();
        autocomplete = null;
      }
    };
  }, []);

  const handlePlaceSelect = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place && place.geometry) {
        onPlaceSelected(place);
      } else {
        console.error("Place data is incomplete.");
      }
    }
  };

  return (
    <FloatingLabel controlId="floatingInput" label="Address">
      <Form.Control
        ref={inputRef}
        type="name"
        className="pt-3 mt-4"
        style={{ borderRadius: "12px", paddingTop: "4%" }}
        placeholder="Address"
      />
    </FloatingLabel>
  );
}

export default AutocompleteInput;
