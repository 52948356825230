import { FloatingLabel, Form } from "react-bootstrap";
import CustomButton from "../button/button.component";
import AutocompleteCountryInput from "../buy-address/autocomplete-country.component";
import AutocompleteInput from "../buy-address/autocomplete-input.component";

import { Fragment, useState } from "react";

const AddressForm = ({ handleSignInClick, section2Enabled, buttonTitle }) => {
  const [country, setCountry] = useState({
    countryName: "",
    countryCode: "",
    address: "",
    unitNumber: "",
  });
  const [isAddressValid, setAddressValid] = useState(false);

  const handlePlaceSelected = (place) => {
    setCountry({ ...country, address: place.formatted_address });
    setAddressValid(true);
  };
  return (
    <Fragment>
      <div style={{ marginTop: "5%" }}>
        <AutocompleteCountryInput
          onPlaceSelected={(place) => {
            setCountry({
              countryCode: place.short_name,
              countryName: place.long_name,
            });
          }}
        />
        {country.countryCode && (
          <AutocompleteInput
            onPlaceSelected={handlePlaceSelected}
            country={country}
          />
        )}
        {country.address && (
          <FloatingLabel
            controlId="floatingInput"
            label="Unit, apartment, suite, etc (optional)"
          >
            <Form.Control
              type="name"
              className="pt-3 mt-4"
              style={{ borderRadius: "12px", paddingTop: "4%" }}
              placeholder="Unit, apartment, suite, etc (optional)"
              onChange={(e) => {
                let unitNumber = e.target.value;
                setCountry({ ...country, unitNumber });
              }}
            />
          </FloatingLabel>
        )}
      </div>
      {!section2Enabled && (
        <div style={{ marginTop: "5%" }}>
          <CustomButton
            title={buttonTitle}
            // processing={isLoading}
            buttonType={"validation"}
            isValid={isAddressValid}
            handleButtonClick={() => {
              handleSignInClick(isAddressValid, country);
            }}
          />
        </div>
      )}
    </Fragment>
  );
};

export default AddressForm;
