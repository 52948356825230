import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ProductServiceDetail from "../../components/product-service-detail/product-service-detail.component";
import { useEffect } from "react";
import ProductServiceList from "../../components/product-list/product-list.component";

const Products = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Redirect only if the current path is exactly /products
    if (location.pathname === '/products') {
      navigate('/products/category/Pets%20and%20Animals');
    }
  }, [navigate, location.pathname]);
  return (
    <Routes>
      <Route index element={<ProductServiceList />} />
      <Route path="details/:productId" element={<ProductServiceDetail />} />
      <Route path="search/:searchedKeyword" element={<ProductServiceList />} />
      <Route path="category/:categoryName" element={<ProductServiceList />} />
      <Route path="*" element={<Navigate to="/products" />} /> 
    </Routes>
    
  );
};

export default Products;
