import { useNavigate } from "react-router-dom";
import { netural400, neturalBlack } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";

const SeacrhItemNotFound = (props) => {
  const { searchedText, type } = props;
  const navigation = useNavigate();
  return (
    <div
      style={{
        width: "100%",
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <CustomLabel
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: neturalBlack,
          }}
        >
          Sorry, we could not find any search results for "{searchedText}".
        </CustomLabel>
        <CustomLabel
          style={{
            fontSize: "18px",
            fontWeight: "500",
            color: netural400,
          }}
        >
          Please try again or browse our{" "}
          <CustomLabel
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: netural400,
              textDecoration: "underline",
            }}
            onClick={() => {
              if (type === "Products") {
                navigation(`/products/category/Pets and Animals`);
              } else {
                navigation(`/services`);
              }
            }}
          >
            list of {type}.
          </CustomLabel>
        </CustomLabel>
      </div>
    </div>
  );
};

export default SeacrhItemNotFound;
