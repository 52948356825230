import { Modal } from "react-bootstrap";
import {
  netural400,
  neturalBlack,
  neutral200,
  primaryGrey,
  primaryOrange,
} from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import CustomCalendar from "../custom-calendar/custom-calendar.component";
import { GET_SCHEDULE } from "../../graphQL/services/services";
import { useLazyQuery } from "@apollo/client";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import CustomButton from "../button/button.component";

const ServiceCalendarModal = (props) => {
  const {} = props;
  const currentDate = new Date();
  let convertedDate = format(currentDate, "yyyy-MM-dd");
  const [selectedDate, setSelectedDate] = useState(
    props.selectedDate !== "" ? props.selectedDate : convertedDate
  );
  const [slots, setSlots] = useState([]);
  const [isSelectedSlots, setIsSelectedSlots] = useState(
    Object.keys(props.selectedTimeSlot).length > 0 ? props.selectedTimeSlot : {}
  );

  const onChangeDate = (value) => {
    setIsSelectedSlots({});
    setSelectedDate(value);
    // console.log("All values------------", value,props.serviceId)
  };

  useEffect(() => {
    if (selectedDate) {
      fetchServiceSchedule();
    }
  }, [selectedDate]);

  const fetchServiceSchedule = () => {
    let formatedDate = format(selectedDate, "yyyy-MM-dd");
    getServiceSchedule({
      variables: {
        serviceId: props.serviceId,
        date: formatedDate,
      },
    });
  };

  const [getServiceSchedule, { loading: loadingServiceSchedule }] =
    useLazyQuery(GET_SCHEDULE, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        let slotsData = data.getSchedule;
      //  console.log("get schedule", slotsData);
        if (slotsData && slotsData.length != 0) {
          setSlots(slotsData);
        } else {
          setSlots([]);
          setIsSelectedSlots({});
        }
      },
    });

  const handleSelectTimeSlot = (item) => {
    setIsSelectedSlots(item);
  };

  const isMultipleOfFour = (number) => {
    return number % 4 === 0;
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className="login-modal-style"
      >
        <div style={{ padding: "3%" }}>
          <Modal.Header
            closeButton
            style={{
              borderBottom: "0px solid rgb(0, 0, 0, 0.1)",
              paddingLeft: "3%",
              paddingRight: "3%",
              backgroundColor: "white",
              borderRadius: "12px",
              paddingBottom: "0px",
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <CustomLabel
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: neturalBlack,
                }}
              >
                Choose an available time
              </CustomLabel>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              marginBottom: "2%",
              paddingLeft: "3%",
              paddingRight: "3%",
              paddingTop: "1%",
            }}
          >
            <div>
              <CustomLabel
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: netural400,
                }}
              >
                You can choose the best time slot available.
              </CustomLabel>
              <div style={{ marginTop: "3%" }}>
                <CustomCalendar
                  selectedDate={selectedDate}
                  onChangeDate={onChangeDate}
                />
              </div>
              <div
                style={{
                  marginTop: "3%",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: isMultipleOfFour(slots.length)
                    ? "space-between"
                    : "flex-start",
                }}
              >
                {loadingServiceSchedule ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <LoadingSpinner />
                  </div>
                ) : !loadingServiceSchedule && slots.length > 0 ? (
                  slots.map((item, index) => {
                    const { startTime, available } = item;
                    const isSelected = isSelectedSlots.startTime === startTime;
                    const isAvailable = available;
                    return (
                      <button
                        key={index}
                        style={
                          isSelected
                            ? {
                                width: "20%",
                                margin: "1%",
                                border: "2px solid #E97101",
                                borderRadius: "12px",
                                display: "flex",
                                justifyContent: "center",
                                color: primaryOrange,
                                marginBottom: "2%",
                              }
                            : !isAvailable
                            ? {
                                width: "20%",
                                margin: "1%",
                                border: "2px solid #DADADA",
                                borderRadius: "12px",
                                display: "flex",
                                justifyContent: "center",
                                color: neutral200,
                                marginBottom: "2%",
                              }
                            : {
                                width: "20%",
                                margin: "1%",
                                border: "2px solid #1B1C1E",
                                borderRadius: "12px",
                                display: "flex",
                                justifyContent: "center",
                                color: neturalBlack,
                                marginBottom: "2%",
                              }
                        }
                        onClick={() => {
                          handleSelectTimeSlot(item);
                        }}
                        disabled={isAvailable ? false : true}
                      >
                        <CustomLabel
                          style={{
                            padding: "8px",
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: isSelected
                              ? primaryOrange
                              : !isAvailable
                              ? primaryGrey
                              : neturalBlack,
                          }}
                        >
                          {" "}
                          {startTime}{" "}
                        </CustomLabel>
                      </button>
                    );
                  })
                ) : !loadingServiceSchedule && slots.length === 0 ? (
                  <CustomLabel
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: neturalBlack,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "5%",
                    }}
                  >
                    No spots available on this date
                  </CustomLabel>
                ) : null}
              </div>
              <div style={{ marginTop: "2%" }}>
                <CustomButton
                  title={"Confirm"}
                  isValid={
                    isSelectedSlots && Object.keys(isSelectedSlots).length > 0
                      ? true
                      : false
                  }
                  // isValid={true}
                  buttonType={"validation"}
                  handleButtonClick={() => {
                    let formatedDate = format(selectedDate, "yyyy-MM-dd");
                    props.handleClick(formatedDate, isSelectedSlots);
                    props.onHide();
                  }}
                />
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ServiceCalendarModal;
