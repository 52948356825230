import { Route, Routes } from "react-router-dom";
import BuyAddress from "../../components/buy-address/buy-address.component";
import CheckoutPage from "../../components/checkout-page/checkout-page.component";

const Buy = () => {
  return (
    <Routes>
      <Route index element={<BuyAddress />} />
      <Route path="checkout" element={<CheckoutPage />} />
      {/* <Route path="search/:searchedKeyword" element={<ProductServiceList />} /> */}
    </Routes>
  );
};

export default Buy;
