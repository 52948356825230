import { IoTrashOutline } from "react-icons/io5";
import IncrementDecrementButton from "../increment-decrement-button/increment-decrement-button.component";
import { netural400, neturalBlack, primaryOrange } from "../../utils/colors";
import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomLabel from "../shared/label/custom-label.component";
import { toast } from "react-toastify";
import "./cart-item-list.style.css";

const CartItemList = ({
  cartProductItem,
  index,
  handleUpdateQuantity,
  handleDeleteCartItem,
}) => {
  const minValue = 1;
  const maxValue = 50;
  const [productQuantity, setProductQuantity] = useState(
    cartProductItem.quantity
  );
  let updateQuantity = null;

  useEffect(() => {
    if (!productQuantity) {
      setProductQuantity("1");
      toast.error("Invalid quantity. Minimum 1 quantity is require", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (
      parseInt(productQuantity) !== parseInt(cartProductItem.quantity)
    ) {
      updateQuantity = {
        quantity: productQuantity.toString(),
        itemId: cartProductItem._id,
        businessId: cartProductItem.businessId,
        variantCombination: cartProductItem.variantCombination
          ? cartProductItem.variantCombination
          : null,
      };
      handleUpdateQuantity(updateQuantity);
    }
  }, [productQuantity]);

  const handleDeleteItem = () => {
    let cartItem = {
      itemId: cartProductItem._id,
      businessId: cartProductItem.businessId,
      variantCombination: cartProductItem.variantCombination
        ? cartProductItem.variantCombination
        : null,
    };
    handleDeleteCartItem(cartItem);
  };

  return (
    <Row key={index} style={{ marginTop: "3%" }}>
      <Col xl={3} lg={4} md={4} sm={4} xs={4}>
        <img
          src={`${process.env.REACT_APP_HOST_URL}${cartProductItem.medias[0].uri}`}
          alt="Cart product"
          className="cart-list-image"
        />
      </Col>
      <Col xl={9} lg={8} md={8} sm={8} xs={8}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label
            style={{
              fontSize: "22px",
              fontWeight: "500",
              color: netural400,
              whiteSpace: "nowrap", // Prevent text from wrapping
              overflow: "hidden", // Hide overflowing text
              textOverflow: "ellipsis", // Display ellipsis for overflow
            }}
          >
            {cartProductItem.title}
          </label>
          {cartProductItem.variantCombination && (
            <label
              style={{
                fontSize: "18px",
                fontWeight: "500",
                color: netural400,
              }}
            >
              {cartProductItem.variantCombination}
            </label>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <label
              style={{
                fontSize: "18px",
                fontWeight: "600",
                color: primaryOrange,
              }}
            >
              $ {cartProductItem.finalTotal}
            </label>
            {cartProductItem.discount !== "0" && (
              <label
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: netural400,
                  textDecoration: "line-through",
                  marginLeft: "2%",
                }}
              >
                $ {cartProductItem.price}
              </label>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* {quantityError && (
              <CustomLabel
                style={{
                  fontSize: "14px",
                  color: "red",
                  width: "100%",
                }}
              >
                {quantityError}
              </CustomLabel>
            )} */}
            <IncrementDecrementButton
              minValue={minValue}
              maxValue={maxValue}
              quantityCount={productQuantity}
              setQuantityCount={setProductQuantity}
            />
            <Link style={{ color: neturalBlack }} onClick={handleDeleteItem}>
              <IoTrashOutline size={25} />
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default CartItemList;
