import { Col } from "react-bootstrap";
import { IoFilter, IoLocationOutline } from "react-icons/io5";
import { BiSortAlt2 } from "react-icons/bi";
import { primaryOrange } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";
import { useEffect, useState } from "react";
import FilterSortMobileModal from "../filter-sort-mobile-modal/filter-sort-mobile-modal.component";
import ServiceLocationModal from "../service-location-modal/service-location-modal.component";

const FilterSortTabletMobile = (props) => {
  const {
    categoryName,
    handleButtonClick,
    setSelectedCategory,
    selectedCategory,
    setSelectedSubcategories,
    selectedSubcategories,
    type,
    filterServiceType,
    resetFilterSort,
    isFliterPrice,
    isFliterDeals,
    setIsFilterDeals,
    setIsFilterPrice,
    handleLocationChange,
    serviceLocationArray,
    isSortValue,
    handleCheckChange,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [showLocationModal, setShoLocationModal] = useState(false);
  const [showModalTitle, setShowModalTitle] = useState("");

  return (
    <>
      <Col
        lg={6}
        md={6}
        sm={type === "Services" ? 4 : 6}
        xs={type === "Services" ? 4 : 6}
        className="filter-sort-section"
        onClick={() => {
          setShowModalTitle("Filters");
          setShowModal(true);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IoFilter color={primaryOrange} />
          <CustomLabel
            style={{
              fontWeight: "bold",
              color: primaryOrange,
              marginLeft: "3%",
            }}
          >
            Filters
          </CustomLabel>
        </div>
      </Col>
      {type === "Services" && (
        <Col
          lg={6}
          md={6}
          sm={4}
          xs={4}
          className="filter-sort-section"
          onClick={() => {
            setShoLocationModal(true);
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <IoLocationOutline color={primaryOrange} />
            <CustomLabel
              style={{
                fontWeight: "bold",
                color: primaryOrange,
                marginLeft: "3%",
              }}
            >
              Location
            </CustomLabel>
          </div>
        </Col>
      )}
      <Col
        lg={6}
        md={6}
        sm={type === "Services" ? 4 : 6}
        xs={type === "Services" ? 4 : 6}
        className="filter-sort-section"
        onClick={() => {
          setShowModalTitle("Sort");
          setShowModal(true);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BiSortAlt2 color={primaryOrange} />
          <CustomLabel
            style={{
              fontWeight: "bold",
              color: primaryOrange,
              marginLeft: "3%",
            }}
          >
            Sort by
          </CustomLabel>
        </div>
      </Col>
      {showLocationModal && (
        <ServiceLocationModal
          show={showLocationModal}
          onHide={() => {
            setShoLocationModal(false);
          }}
          serviceLocationArray={props.serviceLocationArray}
          handleLocationChange={handleLocationChange}
        />
      )}
      {showModal && (
        <FilterSortMobileModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          title={showModalTitle}
          handleButtonClick={handleButtonClick}
          categoryName={categoryName}
          setSelectedCategory={setSelectedCategory}
          selectedCategory={selectedCategory}
          setSelectedSubcategories={setSelectedSubcategories}
          selectedSubcategories={selectedSubcategories}
          type={type}
          mobileFilterServiceType={filterServiceType}
          resetFilterSort={resetFilterSort}
          isMobileFliterPrice={isFliterPrice}
          isMobileFliterDeals={isFliterDeals}
          setIsMobileFilterDeals={setIsFilterDeals}
          setIsMobileFilterPrice={setIsFilterPrice}
          handleLocationChange={handleLocationChange}
          isSortValue={isSortValue}
          handleCheckChange={handleCheckChange}
        />
      )}
    </>
  );
};

export default FilterSortTabletMobile;
