export const primaryOrange = "#E97101";
export const primaryGrey = "#DADADA";
export const netural400 = "#545454";
export const neturalBlack = "#1B1C1E";
export const netural50 = "#F2F2F2";
export const netural500 = "#403F3F";
export const successGreen = "#07bc0c";
export const neturalGreen = "#04A701";
export const neutral200 = "#A6A6A6";
export const success500 = "#04A701";
export const errorRed = "#EA1010";