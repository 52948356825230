import { netural400, neturalBlack } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";

const FilterItemNotFound = (props) => {
  const { resetFilterSort, displayFilterMessage } = props;
  return (
    <div
      style={{
        width: "100%",
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <CustomLabel
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: neturalBlack,
          }}
        >
          Sorry, we could not find any results.
        </CustomLabel>
        {displayFilterMessage && (
          <CustomLabel
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: netural400,
            }}
          >
            Please try using different filters or{" "}
            <CustomLabel
              style={{
                fontSize: "18px",
                fontWeight: "500",
                color: netural400,
                textDecoration: "underline",
              }}
              onClick={() => {
                resetFilterSort();
              }}
            >
              Clear filter.
            </CustomLabel>
          </CustomLabel>
        )}
      </div>
    </div>
  );
};

export default FilterItemNotFound;
