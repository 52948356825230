import { netural400 } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";

const SearchTitleWithCount = (props) => {
  const { searchedText, itemCount } = props;
  return searchedText === "" ? null : (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CustomLabel
        style={{
          fontSize: "14px",
          color: netural400,
        }}
      >
        Search results for
      </CustomLabel>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          marginBottom: "1%",
        }}
      >
        <CustomLabel
          style={{
            fontSize: "32px",
            fontWeight: "600",
          }}
        >
          "{searchedText}"
        </CustomLabel>
        {itemCount && itemCount > 0 ? (
          <CustomLabel
            style={{
              fontSize: "18px",
              marginLeft: "0.5%",
              alignContent: "end",
            }}
          >
            {`(${itemCount})`}
          </CustomLabel>
        ) : null}
      </div>
    </div>
  );
};

export default SearchTitleWithCount;
