import { Form, Modal } from "react-bootstrap";
import CustomLabel from "../shared/label/custom-label.component";
import { netural400, neturalBlack, primaryOrange } from "../../utils/colors";
import { useEffect, useState } from "react";
import { IoTrashSharp } from "react-icons/io5";
import { BsPlusLg } from "react-icons/bs";
import CustomButton from "../button/button.component";
import { useLazyQuery } from "@apollo/client";
import { GET_AVAILABLE_SEATS } from "../../graphQL/services/services";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { useSelector } from "react-redux";

const ServiceSpotsModal = (props) => {
  const currentUser = useSelector(selectCurrentUser);
  const { selectedDate, selectedTimeSlot, noOfPerson, serviceId } = props;
  const [availableSlots, setAvailabeSlots] = useState(0);
  const [addPersonCountArray, setAddPersonCountArray] = useState(
    noOfPerson.length > 0 ? noOfPerson : [{ key: 1, name: currentUser.name }]
  );

  useEffect(() => {
    if (selectedDate !== "") {
      getAvailableSlotSpots({
        variables: {
          serviceId: serviceId,
          selectedDate: selectedDate,
          selectedStartTime: selectedTimeSlot.startTime,
          selectedEndTime: selectedTimeSlot.endTime,
        },
      });
    }
  }, [selectedDate]);

  const [getAvailableSlotSpots, { loading: availableSlotLoading }] =
    useLazyQuery(GET_AVAILABLE_SEATS, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        // console.log(data.getAvailableSlotSpots, "Available slots---");
        setAvailabeSlots(data.getAvailableSlotSpots);
      },
    });

  const handleNameChange = (nameValue: any, index: number) => {
    let list = [...addPersonCountArray];
    list[index].name = nameValue;
    setAddPersonCountArray(list);
  };

  const handleDeleteName = (index: number) => {
    const list = [...addPersonCountArray];
    list.splice(index, 1);
    setAddPersonCountArray(list);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="login-modal-style"
    >
      <div style={{ padding: "3%" }}>
        <Modal.Header
          closeButton
          style={{
            borderBottom: "0px solid rgb(0, 0, 0, 0.1)",
            paddingLeft: "3%",
            paddingRight: "3%",
            backgroundColor: "white",
            borderRadius: "12px",
            paddingBottom: "0px",
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            <CustomLabel
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: neturalBlack,
              }}
            >
              Number of people
            </CustomLabel>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            marginBottom: "2%",
            paddingLeft: "3%",
            paddingRight: "3%",
            paddingTop: "1%",
          }}
        >
          {availableSlotLoading ? (
            <LoadingSpinner />
          ) : (
            <div>
              <CustomLabel
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: netural400,
                }}
              >
                Select number of spots you want to book for this service. Price
                is per spot taken.
              </CustomLabel>
              <div
                style={{
                  marginTop: "5%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CustomLabel
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: netural400,
                  }}
                >
                  Spots remaining:
                </CustomLabel>
                <CustomLabel
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: neturalBlack,
                    marginLeft: "1%",
                  }}
                >
                  {availableSlots}
                </CustomLabel>
              </div>
              <div style={{ marginTop: "5%" }}>
                <Form.Group style={{ color: "#A6A6A6" }}>
                  <Form.Control
                    required
                    style={{ borderRadius: "12px", height: "40px" }}
                    type="text"
                    value={addPersonCountArray[0].name}
                    placeholder={"Full name (Person 1)"}
                    onChange={(value) => {
                      handleNameChange(value.target.value, 0);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid name.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              {addPersonCountArray.map((personCount, index) =>
                index + 1 > 1 ? (
                  <div
                    key={index}
                    style={{
                      marginTop: "3%",
                      // display: "flex",
                      // flexDirection: "row",
                      // alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Form.Group
                        style={{
                          color: "#A6A6A6",
                          marginRight: "1%",
                          width: "90%",
                        }}
                      >
                        <Form.Control
                          required
                          style={{ borderRadius: "12px", height: "40px" }}
                          type="text"
                          value={personCount.name}
                          placeholder={
                            "Full name (Person " + parseInt(index + 1) + ")"
                          }
                          minLength={3}
                          maxLength={30}
                          onChange={(value) => {
                            handleNameChange(value.target.value, index);
                          }}
                        />
                      </Form.Group>
                      <IoTrashSharp
                        color={primaryOrange}
                        size={20}
                        style={{
                          width: "10%",
                          display: "flex",
                          justifyContent: "end",
                        }}
                        onClick={() => {
                          handleDeleteName(index);
                        }}
                      />
                    </div>
                  </div>
                ) : null
              )}
              {availableSlots > addPersonCountArray.length ? (
                <div
                  style={{
                    marginTop: "5%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setAddPersonCountArray([
                      ...addPersonCountArray,
                      { key: addPersonCountArray.length + 1, name: "" },
                    ]);
                  }}
                >
                  <BsPlusLg
                    style={{ marginRight: "1%", color: primaryOrange }}
                    size={18}
                  />
                  <CustomLabel
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: primaryOrange,
                    }}
                  >
                    Add person
                  </CustomLabel>
                </div>
              ) : null}
              <div style={{ marginTop: "5%" }}>
                <CustomButton
                  title={"Continue"}
                  isValid={availableSlots !== 0 ? true : false}
                  buttonType={"validation"}
                  handleButtonClick={() => {
                    props.handleClick(addPersonCountArray);
                    props.onHide();
                  }}
                />
              </div>
            </div>
          )}
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ServiceSpotsModal;
