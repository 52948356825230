import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { netural400, neturalBlack } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";
import { APPLE_STORE_URL, GOOGLE_STORE_URL } from "../../utils/constants";
import Footer from "../home-page-footer/home-page-footer.component";
import "./membership.style.css";

const Membership = () => {
  return (
    <>
      <Container style={{ width: "99%", height: "80vh" }}>
        <div style={{ width: "99%", marginTop: "2%" }}>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col xl={11} lg={11} md={11} sm={11} xs={11}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "1%",
                  }}
                >
                  <Link to={"/"}>
                    <CustomLabel
                      style={{
                        fontSize: "14px",
                        color: netural400,
                      }}
                    >
                      Home
                    </CustomLabel>{" "}
                  </Link>
                  <label style={{ marginLeft: "1%", marginRight: "1%" }}>
                    /
                  </label>
                  <Link>
                    <CustomLabel
                      style={{
                        fontSize: "14px",
                        color: netural400,
                      }}
                    >
                      Membership
                    </CustomLabel>{" "}
                  </Link>
                </div>
                <CustomLabel
                  style={{
                    fontSize: "32px",
                    fontWeight: "600",
                  }}
                >
                  Membership
                </CustomLabel>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  alt="membership"
                  src="./images/membership.png"
                  className="coming-soon-image"
                />
                <CustomLabel
                  style={{
                    fontSize: "24px",
                    fontWeight: "600",
                    color: neturalBlack,
                  }}
                >
                  Membership tiers is just a tap away on our app.
                </CustomLabel>
                <CustomLabel
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: netural400,
                  }}
                >
                  Unlock exclusive benefits by upgrading your membership through
                  our app!
                </CustomLabel>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "3%",
                    marginBottom: "5%",
                  }}
                >
                  <a
                    href={GOOGLE_STORE_URL}
                    style={{ marginRight: "3%" }}
                    target="_blank"
                  >
                    <img src="./images/Badge.png" alt="" />
                  </a>
                  <a href={APPLE_STORE_URL} target="_blank">
                    <img src="./images/Frame 11.png" alt="" />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Membership;
